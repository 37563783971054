/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom'

const mapStateToProps = (state) => ({
  auth: state.auth,
})

class Private extends Component {
  render() {
    const { auth, children, location } = this.props
    return auth.token ? (
      <Route render={() => React.cloneElement(children, this.props)} />
    ) : (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location },
        }}
      />
    )
  }
}

export default withRouter(connect(mapStateToProps)(Private))
