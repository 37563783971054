import React from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import PlaceholderHistory from './placeholder'

const ContainerHistory = styled.div`
  width: 100%;
  padding-top: 2rem;
`

const Risk = styled.p`
  font: 700 0.75rem 'Open Sans', sans-serif;
  color: ${(props) => props.color};
`

const BoxHistory = styled.div`
  position: relative;
  padding: 1.5rem 0.75rem 0;
  border: 0.0625rem solid #0061af20;
  border-radius: 0.3125rem;
  margin-bottom: 1.5rem;
`

const HistoryDate = styled.p`
  position: absolute;
  top: -0.75rem;
  left: 0.5rem;
  padding: 0 0.25rem;
  font: 400 1rem 'Open Sans', sans-serif;
  color: #0061af;
  background: #fff;
`

const HistoryText = styled.p`
  margin-bottom: 1rem;
  font: 400 0.75rem 'Open Sans', sans-serif;
  font-weight: ${(props) => props.fontWeight};
  font-style: ${(props) => props.italic && 'italic'};
`
const PatientHistory = (props) => {
  const historyOrdered =
    props.history &&
    props.history.sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt)
    })

  const historyFiltered =
    props.history &&
    historyOrdered.filter((history) => {
      return (
        history.id !== props.selectedTicket.id &&
        (history.newStatus === 'Finalizado' ||
          history.newStatus === 'Encaminhado para pronto socorro' ||
          history.newStatus === 'Encaminhado para especialista focal')
      )
    })

  return (
    <ContainerHistory>
      {props.isFetchingSidebarTab ? (
        <PlaceholderHistory />
      ) : (
        historyFiltered &&
        historyFiltered.length > 0 &&
        historyFiltered.map((item, index) => {
          const treatData = props.treatData(item.trial)
          const state = treatData && treatData.find((i) => i.key === 'Estado')

          return (
            <BoxHistory key={index}>
              <HistoryDate>
                Atendimento de{' '}
                {item.updatedAt
                  ? format(new Date(item.updatedAt), 'dd/MM/yyyy')
                  : ''}
              </HistoryDate>
              <Risk color={props.chooseColor(item.priority)}>
                Prioridade {props.choosePacientRiskLabel(item.priority)}
              </Risk>
              <HistoryText fontWeight={700}>{item.status}</HistoryText>
              <HistoryText>{state && state.valors}</HistoryText>
            </BoxHistory>
          )
        })
      )}
    </ContainerHistory>
  )
}

export default PatientHistory
