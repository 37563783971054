// Libs
import axios from 'axios';
import jwt from 'jsonwebtoken';

import {
  login,
  raiseLoginError,
  clearErrorMessage,
  isFetching
} from '../modules/authentication-module';

let connection = axios.create({
  baseURL: process.env.REACT_APP_ZENDESK_URL,
  timeout: 35000,
  headers: {
    'Content-Type': 'application/json',
    'channel': 'telemedicine'
  }
});

export const loginRequest = (email, password) => {
  return (dispatch) => {
    dispatch(isFetching());
    dispatch(clearErrorMessage());
    connection({
      method: 'post',
      url: '/login',
      data: {
        username: email,
        password: password
      }
    }).then((result) => {
      let token = jwt.decode(result.data.accessToken);
      const userdata = {
        name: token["cognito:username"],
        refreshToken: result.data.refreshToken,
        expirationDate: result.data.expirationDate
      }
      Promise.all([connection({
        method: 'get',
        url: `/users/${email}/groups`,
        headers: {
          Authorization: result.data.accessToken
        }
      }), connection({
        method: 'get',
        url: `/users/${email}/profiles`,
        headers: {
          Authorization: result.data.accessToken
        }
      })]).then((values) => {
        userdata.groups = values[0].data;
        userdata.profile = values[1].data[0];
        dispatch(login(result.data.accessToken, userdata));
      });
    }).catch((err) => {
      console.log(err);
      dispatch(raiseLoginError('Usuário ou senha incorretos'));
    });
  };
};
