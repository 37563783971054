import React, { Fragment } from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import {
	format,
	add,
 } from 'date-fns';

// Components
// import Scrollbar from "../../components/scrollbar";

// Images
import semConsulta from '../../assets/sem_consulta.png'

import { getNotesByPatient } from "../../dataflow/thunks/dashboard-thunks";

const mapStateToProps = state => ({
  tickets: state.dashboard.tickets,
  selectedFilters: state.dashboard.selectedFilters,
  notification: state.dashboard.notification,
});

const mapDispatchToProps = dispatch => ({
  getNotesByPatient: cpf => dispatch(getNotesByPatient(cpf)),
});

// Style
const Content = styled.div`
	position: relative;
	width: calc(100% - 28rem);
  height: ${props => props.height || '100%'};
	overflow-y: scroll;
	margin-top: 0.875rem;

	&::-webkit-scrollbar {
    display: none;
  }
`;

const ContentGeral = styled.div`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	width: 100%;
	padding: 1.5rem 2rem;
`;

const AppointmentItem = styled.div`
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 100%;
	padding: 1.5rem 2rem;
`;

const AppointmentTitleBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-bottom: 1.5rem;
`;

const AppointmentTitle = styled.p`
	padding: .25rem 1.25rem;
	border-radius: .25rem;
	background: #A3E6FF33;
	box-shadow: 0 .1875rem .375rem #00000029;
	font: 600 1rem 'Open Sans', serif;
	color: #0561AF;
	text-align: center;
`;

const AppointmentCard = styled.div`
	width: 16rem;
	margin: 1rem .59375rem;
`;

const PacientCard = styled.li`
	position: relative;
  background: #FFFFFF72;
	border: .125rem solid ${props => props.color};
	border-radius: .4375rem;
  transition: background 0.3s, border 0.3s;
	list-style: none;
	cursor: ${props => !props.isFinished && 'pointer'};
	opacity: ${props => (props.selected || props.isFinished) ? 1 : 0.4};
	transition: opacity 0.2s;

	:hover {
		opacity: 1;
	}
`;

const PacientCardnName = styled.p`
	margin: 0.75rem 0.75rem 0.375rem;
	font: 700 0.9625rem "Open Sans", serif;
	color: #333333;
  text-transform: capitalize;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: calc(100% - 1.5rem);
	overflow: hidden;
`;

const PacientCardLabel = styled.p`
	margin: 0 .75rem .375rem;
	font: ${props => props.fontWeight} 0.8125rem "Open Sans", serif;
	color: ${props => props.color || '#333333'};
`;

const PacientCardButton = styled.button`
	width: 100%;
	padding: 0.625rem;
	font: 400 0.75rem "Open Sans", serif;
	color: #FFFFFF;
	background: ${props => props.color};
	border: none;
	cursor: pointer;
	outline: none;
`;

const PacientCardRisk = styled.p`
	position: absolute;
	top: -1.25rem;
	right: 0.5rem;
	font: 600 0.75rem "Open Sans", serif;
	letter-spacing: 0;
  color: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    width: 0.5rem;
    height: 0.5rem;
    background: ${props => props.color};
    border-radius: 100%;
    display: block;
    margin-left: 0.25rem;
  }
`

const PacientCardBox = styled.span`
  display: flex;
  justify-content: space-between;
`;

const EmptyMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 28rem);
  height: 100%;
`;

const EmptyMessageIcon = styled.img`
  width: 50%;
	max-width: 28.125rem;
`;

const EmptyMessageText = styled.p`
  margin-top: 2rem;
  width: 70%;
  text-align: center;
  font: 700 1.375rem 'Open Sans';
  color: #0061AF;
  opacity: 1;
`;

const RengeScroll = styled.input`
  width: 0.25rem;
	height: 100%;
	-webkit-appearance: slider-vertical;
	transform: rotate(180deg);

	:focus {
		outline: none;
	}
	&::-webkit-slider-runnable-track {
		width: 100%;
		height: 0.375rem;
		cursor: pointer;
		/* animate: 0.2s; */
		box-shadow: 0 0 0 #000000;
		background: #00AEEF;
		border-radius: .0625rem;
		border: 0 solid #000000;
	}
	&::-webkit-slider-thumb {
		box-shadow: 0 0 0 #000000;
		border: .0625rem solid #FFFFFF00;
		height: 1.125rem;
		width: 1.125rem;
		border-radius: 1.5625rem;
		background: #0061AF;
		cursor: pointer;
		-webkit-appearance: none;
		margin-right: -0.5rem;
	}
	&:focus::-webkit-slider-runnable-track {
		background: #00AEEF;
	}
	&::-moz-range-track {
		width: 100%;
		height: .375rem;
		cursor: pointer;
		box-shadow: 0 0 0 #000000;
		background: #00AEEF;
		border-radius: .0625rem;
		border: 0 solid #000000;
	}
	&::-moz-range-thumb {
		box-shadow: 0 0 0 #000000;
		border: .0625rem solid #FFFFFF00;
		height: 1.125rem;
		width: 1.125rem;
		border-radius: 1.5625rem;
		background: #0061AF;
		cursor: pointer;
	}
	&::-ms-track {
		width: 100%;
		height: .3125rem;
		cursor: pointer;
		/* animate: 0.2s; */
		background: transparent;
		border-color: transparent;
		color: transparent;
	}
	&::-ms-fill-lower {
		background: #00AEEF;
		border: 0 solid #000000;
		border-radius: .125rem;
		box-shadow: 0 0 0 #000000;
	}
	&::-ms-fill-upper {
		background: #00AEEF;
		border: 0 solid #000000;
		border-radius: .125rem;
		box-shadow: 0 0 0 #000000;
	}
	&::-ms-thumb {
		margin-top: .0625rem;
		box-shadow: 0 0 0 #000000;
		border: .0625rem solid #FFFFFF00;
		height: 1.125rem;
		width: 1.125rem;
		border-radius: 1.5625rem;
		background: #0061AF;
		cursor: pointer;
	}
	&:focus::-ms-fill-lower {
		background: #00AEEF;
	}
	&:focus::-ms-fill-upper {
		background: #00AEEF;
	}
`;

const RendeContent = styled.div`
	position: absolute;
	top: 2.75rem;
	right: 0;
	width: 1rem;
	height: 90%;
	margin: auto 0;
`;

const RangeLabel = styled.span`
	position: absolute;
	/* top: ${props => `calc((${props.top}% + (1.3125rem))`}; */
	right: 1.5625rem;
	text-align: center;
	margin-top: -.34325rem;
	padding: 0.25rem 1rem;
	top: ${props => `calc(${props.top}% - ${props.altTop}px)`};
	background: #A3E6FF 0% 0% no-repeat padding-box;
	box-shadow: 0 .1875rem .375rem #00000029;
	border-radius: .3125rem;
	opacity: 1;
	font: 600 1rem Open Sans;
	color: #0561AF;
`;

const ScrollMark = styled.div`
	position: absolute;
	top: ${props => `${props.top}%`};
	left: -.21875rem;
	width: 0.625rem;
	height: 0.625rem;
	border-radius: 50%;
	background-color: #0061AFCC;
	cursor: pointer;
`;

class PatientCardList extends React.Component {
  getListTitle = (date, isLabel) => {
    let dateParts = date.split("/");
    let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    let currentDate = new Date();

    if (format(new Date(), 'dd/MM/yyyy') === date || dateObject < currentDate) {
      if (isLabel) {
        return 'Hoje';
      } else {
        return null;
      }
    } else if (format(add(new Date(), { days: 1 }), 'dd/MM/yyyy') === date) {
      return 'Amanhã';
    } else if (format(add(new Date(), { days: 2 }), 'dd/MM/yyyy') === date) {
      return 'Em 2 dias';
    } else { return date }
  }

  showEmptyMessage = () => (
    <EmptyMessageContainer>
      <EmptyMessageIcon src={semConsulta} />
      <EmptyMessageText>
        Todos os pacientes já foram atendidos. Não há novos atendimentos em espera.
      </EmptyMessageText>
    </EmptyMessageContainer>
  )

  showClient = (client) => {
    if (client === 'nubank') {
      return 'cliente NuBank';
    } else if (client === 'itau') {
      return 'cliente Itaú';
    } else if (client === 'general-practice') {
      return 'ADMA HEALTH';
    } else {
      return 'cliente HSL';
    }
  }

  renderDate = (card) => {
    const filters = this.props.selectedFilters;
    if (card.ticket.doctorType === "enfermeiro") {
      if (
        filters[0].selected.includes("Aguardando teleconsulta") ||
        filters[0].selected.includes("Em atendimento")
      ) {
        return card.ticket.createdAt ? `Entrada: ${format(new Date(card.ticket.createdAt), 'dd/MM/yyyy, HH:mm')}` : ``
      }
      if (
        filters[0].selected.includes("Retorno com Enfermeiro") ||
        filters[0].selected.includes("Encaminhado para Enfermeiro")
      ) {
        return `Agendada: ${card.appointmentDate}`
      }
      if (
        filters[0].selected.includes("Finalizado") ||
        filters[0].selected.includes("Encaminhado para pronto socorro") ||
        filters[0].selected.includes("Encaminhado para especialista focal")
      ) {
        return `Realizada em: ${format(new Date(card.ticket.updatedAt), 'dd/MM/yyyy, HH:mm')}`
      }
    } else {
      if (
        filters[0].selected.includes("Aguardando teleconsulta") ||
        filters[0].selected.includes("Em atendimento")
      ) {
        return `Agendado para: ${card.appointmentDateAndHour}`
      }
      if (
        filters[0].selected.includes("Finalizado") ||
        filters[0].selected.includes("Encaminhado para pronto socorro") ||
        filters[0].selected.includes("Encaminhado para especialista focal")
      ) {
        return `Realizada em: ${format(new Date(card.ticket.updatedAt), 'dd/MM/yyyy, HH:mm')}`
      }
    }
  }

  showAppointmentCard = (card) => {
    const isFinished = card.newStatus === 'Finalizado'
      || card.newStatus === 'Encaminhado para pronto socorro'
      || card.newStatus === 'Encaminhado para especialista focal';

    const choosePacientRiskColor = () => {
      if (isFinished) {
        return '#0061AF';
      }
      if (this.props.userType === 'physician') {
        return '#0061AF';
      } else if (card.newStatus.includes("Em Atendimento")) {
        return '#cecece4c'
      } else {
        switch (card.priority) {
          case "media":
            return "#7800AF";
          case "alta":
            return "#AF0000";
          case "baixa":
          default:
            return '#00AFEF';
        }
      }
    };

    const calcAge = (dateString) => {
      var birthday = +new Date(dateString);
      return ~~((Date.now() - birthday) / (31557600000));
    }

    const selectCard = (ev) => {
      ev.stopPropagation();
      if (card.id === this.props.selectedCardId) {
        this.props.handleSetSelectedCardId(undefined);
      } else {
        this.props.handleSetSelectedCardId({ ...card, isAttend: true, });
        this.props.getNotesByPatient(card.ticket.cpf)
      }
    }

    let appointmentDateHour
    const selected = this.props.selectedCardId === undefined || card.id === this.props.selectedCardId

    if (card.ticket.appointmentDate) {
      appointmentDateHour = format(new Date(card.ticket.appointmentDate), 'HH:mm');
    }

    const isNewCard = this.props.notification.find(id => id === card.id);

    return (
      <PacientCard
        key={card.id}
        disabled={false}
        color={choosePacientRiskColor()}
        selected={selected}
        small={false}
        isFinished={isFinished}
        onClick={!isFinished ? selectCard : null}
      >
        <div>
          {isNewCard ? (
            <PacientCardRisk color='#FF9100'>
              Novo atendimento <span></span>
            </PacientCardRisk>
          ) : (
            <PacientCardRisk color = {choosePacientRiskColor()}>
              Prioridade {card.priority}
            </PacientCardRisk>
          )}
          <PacientCardnName>
            {card.pacient.toLowerCase()}
          </PacientCardnName>
          <PacientCardBox>
            <PacientCardLabel fontWeight={400}>
              {card.ticket.gender.toLowerCase() === 'masculino' ? 'Homem' : 'Mulher'}, {calcAge(new Date(card.ticket.birthday))} anos
						</PacientCardLabel>
            {/* <PacientCardLabel fontWeight={700}>
              {card.ticket.type ? this.showClient(card.ticket.type) : 'cliente HSL'}
            </PacientCardLabel> */}
          </PacientCardBox>
          <PacientCardLabel fontWeight={400}>
            {this.renderDate(card)}
					</PacientCardLabel>
          <PacientCardLabel fontWeight={400} color={isFinished ? '#333333' : choosePacientRiskColor()}>
            Status: {card.ticket.newStatus}
          </PacientCardLabel>
          {isFinished && (
            <PacientCardLabel fontWeight={400} color={isFinished ? '#333333' : choosePacientRiskColor()}>
              Desfecho: {card.ticket.status}
            </PacientCardLabel>
          )}
        </div>
        {!isFinished && (
          <PacientCardButton color={choosePacientRiskColor()}>
            Selecionar
          </PacientCardButton>
        )}
      </PacientCard>
    );
  }

  renderScroll = () => {
    let dates = [];

    this.props.tickets.forEach((ticket) => {
      if (!dates.includes(ticket.date)) {
        dates.push(ticket.date);
      }
    });

    const renderMarks = () => {
      if (dates.length > 0) {
        return dates.map((date, i) => {
          const getTop = () => {
            return i === 0 ? 0 : 'calculate value'
          };

          const goToMarker = () => {
            console.log('marker');
          };

          return (
            <ScrollMark
              key={date}
              top={getTop()}
              onClick={goToMarker}
            />
          );
        })
      } else {
        return null;
      }
    }

    return (
      <RendeContent>
        <RengeScroll
          value={0}
          type='range'
          max={100}
          min={0}
          orient='vertical'
          // onInput={this.handleRangeScroll}
          id='input-range'
        />
        {renderMarks()}
        <RangeLabel top={0} altTop={0.165 * 0}>
          Hoje
				</RangeLabel>
      </RendeContent>
    )
  }

  showAppointmentsList = () => {
    if (this.props.showFilteredDates) {
      let dates = {};

      this.props.tickets.forEach((ticket) => {
        if (ticket.appointmentDate) {
          if (dates[ticket.appointmentDate]) {
            dates[ticket.appointmentDate].push(ticket);
          } else {
            dates[ticket.appointmentDate] = [ticket];
          }
        } else {
          if (dates[ticket.date]) {
            dates[ticket.date].push(ticket);
          } else {
            dates[ticket.date] = [ticket];
          }
        }
      });

      const showFilteredAppointments = () => {
        return Object.keys(dates).map((date) => {
          const showAppointment = () => {
            return dates[date].map((appointment) => {
              return (
                <AppointmentCard key={appointment.id}>
                  {this.showAppointmentCard(appointment)}
                </AppointmentCard>
              );
            });
          }

          // comparar a data com a de hoje e alterar a exibição pode ser aqui
          return (
            <AppointmentItem key={date}>
              {this.getListTitle(date)
                ? <AppointmentTitleBox>
                    <AppointmentTitle>
                      {this.getListTitle(date) ? this.getListTitle(date) : null}
                    </AppointmentTitle>
                  </AppointmentTitleBox>
                : null
              }
              {showAppointment()}
            </AppointmentItem>
          );
        });
      }

      return showFilteredAppointments();
    } else {
      return (
        <ContentGeral>
          {this.props.tickets.map((ticket) => (
            <AppointmentCard key={ticket.id}>
              {this.showAppointmentCard(ticket)}
            </AppointmentCard>
          ))}
        </ContentGeral>
      );
    }
  }

  render() {
    if (this.props.tickets && this.props.tickets.length > 0) {
      return (
        <Fragment>
          <Content id="scrolling-container">
            {this.showAppointmentsList()}
          </Content>
          {this.props.showFilteredDates
            ? this.renderScroll()
            : null
          }
        </Fragment>
      );
    } else {
      return this.showEmptyMessage()
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientCardList);
