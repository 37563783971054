/* eslint-disable no-return-assign */
// Libs
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, { keyframes } from 'styled-components'

// Images
import logo from '../../assets/sirio.png'
import loading from '../../assets/loading-purple.svg'

// Redux
import { loginRequest } from '../../dataflow/thunks/authentication-thunks'

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = (dispatch) => ({
  loginRequest: (email, password) => {
    dispatch(loginRequest(email, password))
  },
})

// Styles
const Container = styled.div`
  /* display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%; */
  display: flex;
  height: 100%;
`

const LoginHeader = styled.header`
  width: 40%;
  height: 100%;
  padding: 0 7.5%;
  background: #0561af
    linear-gradient(90deg, #0561af 0%, #028ad1 27%, #00afef 100%);

  @media (min-width: 1600px) {
    padding: 0 14%;
  }
`

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 90rem;
  margin: 0 auto;
`

const LoginLogo = styled.img`
  /* width: 11.25rem; */
`

const LoginForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  /* align-items: center; */
  width: 60%;
  padding: 2.8125rem 0;
`

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 25rem;
`

const SubTitle = styled.h2`
  margin-bottom: 2.5rem;
  font: 400 1.5rem/2rem 'Open Sans', sans-serif;
  text-transform: uppercase;
  color: #000;
`

const LoginField = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  height: 3.75rem;
  margin: 1rem 0;
  padding: 0 1rem;
  border: 0.125rem solid ${(props) => props.hasBorder && props.hasBorder};
  border-radius: 0.5rem;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`

const LoginSpan = styled.span`
  position: absolute;
  top: -0.625rem;
  left: 0.5rem;
  padding: 0 0.375rem;
  background: #fff;
  font: 400 0.75rem 'Open Sans', sans-serif;
  color: #0061af;
`

const LoginInput = styled.input`
  width: 100%;
  border: none;
  font: 400 1rem 'Open Sans', sans-serif;
  color: #131516;
  outline: none;
`

const ErrorMessage = styled.span`
  margin-bottom: 2rem;
  font: 400 0.75rem 'Open Sans', sans-serif;
  color: #af0000;
`

const LoginText = styled.p`
  margin-bottom: 2rem;
  font: 400 0.75rem 'Open Sans', sans-serif;
  color: #131516;
`

const LoginButton = styled.button`
  align-self: flex-end;
  padding: 0.375rem 2rem;
  border: none;
  border-radius: 0.5rem;
  background: ${(props) => (props.disabled ? '#0061af4C' : '#0061af')};
  font: 400 1rem 'Open Sans', sans-serif;
  color: #fff;
  outline: none;
  cursor: ${(props) => (props.disabled ? '' : 'pointer')};
`

const load = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Loading = styled.img`
  width: 1rem;
  height: 1rem;
  animation: 1s linear infinite ${load};
`

class Login extends Component {
  constructor() {
    super()

    this.state = {
      isFormatEmail: undefined,
      isPassword: undefined,
    }
  }

  componentDidUpdate(oldProps) {
    if (
      this.props.auth.token !== oldProps.auth.token &&
      this.props.auth.token
    ) {
      this.props.history.replace({ pathname: '/atendimento' })
    }
  }

  validateEmail = (email) => {
    const expression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    return expression.test(String(email).toLowerCase())
  }

  handleCheckPassword = (ev) => {
    const password = ev.target.value

    if (password.length >= 3) {
      this.setState({
        isPassword: true,
      })
    } else {
      this.setState({
        isPassword: false,
      })
    }
  }

  loginRequest = (event) => {
    event.preventDefault()
    this.props.loginRequest(
      event.target.email.value,
      event.target.password.value
    )
  }

  renderLogin = () => {
    const { isFormatEmail, isPassword } = this.state

    const chooseColor = (param) => {
      if (param) {
        return '#0061AF'
      }
      if (param !== undefined) {
        return '#AF0000'
      }
      return '#707373'
    }

    return (
      <LoginForm onSubmit={this.loginRequest}>
        <FormContent>
          <SubTitle>Central de atendimento clínico remoto</SubTitle>
          <LoginField hasBorder={chooseColor(isFormatEmail)}>
            <LoginSpan>Usuário</LoginSpan>
            <LoginInput
              ref={(node) => (this.email = node)}
              name='email'
              autoFocus
            />
          </LoginField>
          <LoginField hasBorder={chooseColor(isPassword)}>
            <LoginSpan>Senha</LoginSpan>
            <LoginInput
              onChange={this.handleCheckPassword}
              type='password'
              name='password'
            />
          </LoginField>
          {this.props.auth.error ? (
            <ErrorMessage>
              Usuário ou senha inválido. Verifique seus dados e tente novamente.
            </ErrorMessage>
          ) : null}
          <LoginText>
            Caso esqueça sua senha, acione o Service Desk por meio do ramal
            0990, opção 2.
          </LoginText>
          <LoginButton
            disabled={this.props.auth.isFetching}
            type='submit'
            styleName='form-login__submit'
          >
            {this.props.auth.isFetching ? <Loading src={loading} /> : 'entrar'}
          </LoginButton>
        </FormContent>
      </LoginForm>
    )
  }

  render() {
    return (
      <Container>
        <LoginHeader>
          <ContentHeader>
            <LoginLogo src={logo} />
          </ContentHeader>
        </LoginHeader>
        {this.renderLogin()}
      </Container>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
