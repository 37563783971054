// Libs
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

// Components
import Header from "../../components/header";
import Sidebar from './Sidebar'
import Room from './room'
import Placeholder from '../../components/placeholder-card'
import PatientCardList from "./pacientListNew";
import AfterAppointment from "./afterAppointment";

// import Dropdown from "./dropdown";
// import StatusModal from "./status-modal";
// import PacientModal from "./pacient-modal";
// import PacientChatButton from "../../components/pacient-chat-button";

import notifcationIcon from '../../assets/notfi-white.svg';
import closeIcon from '../../assets/close-white.svg';
import sound1 from '../../assets/audio.ogg';
import sound2 from '../../assets/audio.mp3';

// Mock
import pacients from "./mock";

// Redux
import {
  getTicketsByPatient,
  getTicketsThunk,
  updateTicketThunk,
  closeTicketThunk,
  applyFilterTicketsThunk,
  endCallSendEmailThunk,
  satisfactionSurveyThunk,
} from "../../dataflow/thunks/dashboard-thunks";

import {
  saveSelectedTicket,
  updateOrdination,
  startFirstFetching,
  toggleNotification,
} from "../../dataflow/modules/dashboard-module";

// eslint-disable-next-line no-unused-vars
import { db } from "../../dataflow/firebase";
import { differenceInMinutes } from "date-fns";

const mapStateToProps = state => ({
  auth: state.auth,
  tickets: state.dashboard.tickets,
  selectedTicket: state.dashboard.selectedTicket,
  filters: state.dashboard.filters,
  selectedFilters: state.dashboard.selectedFilters,
  orderedBy: state.dashboard.orderedBy,
  isFetching: state.dashboard.isFetching,
  firstFetching: state.dashboard.firstFetching,
  notification: state.dashboard.notification,
});

const mapDispatchToProps = dispatch => ({
  getTicketsByPatient: info => dispatch(getTicketsByPatient(info)),
  getTicketsThunk: info => dispatch(getTicketsThunk(info)),
  saveSelectedTicket: ticket => dispatch(saveSelectedTicket(ticket)),
  updateTicketThunk: (ticket, status) =>
    dispatch(updateTicketThunk(ticket, status)),
  closeTicketThunk: ticket => dispatch(closeTicketThunk(ticket)),
  updateOrdination: info => dispatch(updateOrdination(info)),
  applyFilterTicketsThunk: info => dispatch(applyFilterTicketsThunk(info)),
  startFirstFetching: info => dispatch(startFirstFetching(info)),
  toggleNotification: info => dispatch(toggleNotification(info)),
  endCallSendEmailThunk: (data) => endCallSendEmailThunk(data),
  satisfactionSurveyThunk: (info) => dispatch(satisfactionSurveyThunk(info)),
});

const Container = styled.div`
	width: 100vw;
	height: 100vh;
	padding: 0.75rem;
	background: #F6F9FC;
`;

const ContainerBox = styled.div`
  position: relative;
	display: flex;
	height: calc(100vh - 7rem);
`;

const PopupNotifcation = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 3rem;
  background: #0061AF;
  color: #fff;
  padding: 0.75rem 2.25rem 1rem 2.25rem;
  box-shadow: 0px 3px 10px #00000017;
  border-radius: 4px;
  h3 {
    position: relative;
    padding-bottom: 0.55rem;
    &:after {
      position: absolute;
      bottom: 0.75rem;
      left: -1.45rem;
      width: 1rem;
      height: 1rem;
      content: "";
      background: url(${notifcationIcon}) no-repeat;
      background-position: center center;
    }
    &:before {
      position: absolute;
      bottom: 0.85rem;
      right: -1.45rem;
      width: 0.9rem;
      height: 0.9rem;
      content: "";
      background: url(${closeIcon}) no-repeat;
      background-position: center center;
    }
  }
`;

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pacients,
      dropdown: {
        type: "",
        isActive: false
      },
      filters: [
        {
          type: "priority",
          isActive: false,
          label: "Prioridade"
        },
        {
          type: "status",
          isActive: false,
          label: "status"
        }
      ],
      pacientModal: {
        isActive: false,
        pacient: {}
      },
      statusModal: {
        isActive: false,
        pacient: {}
      },
      isHoverd: undefined,
      selectedCardId: undefined,
      // talvez mudar para o Redux
      inRoom: false,
      leavingRoom: false,
      isVideo: false,
      doctor: this.props.doctor,
      doctorName: this.props.doctorName,
    };

    this.timer = null;
  }

  componentDidMount() {
    this.props.applyFilterTicketsThunk(this.props.selectedFilters);
    this.timer = setInterval(() => {
      this.props.getTicketsThunk();
    }, 45000);

    let waitingPatient = localStorage.getItem('waitingPatient');
    waitingPatient = JSON.parse(waitingPatient)
    
    if (waitingPatient) {
      const now = new Date();
      const howLongIsWaiting = differenceInMinutes(now, new Date(waitingPatient.dateInitRoom));

      if (howLongIsWaiting > 30) {
        localStorage.removeItem('waitingPatient')
      } else {
        this.handleSetSelectedCardId(waitingPatient.pacient)

        this.setState({
          doctor: waitingPatient.doctor,
          doctorName: waitingPatient.doctorName,
          isVideo: waitingPatient.isVideo,
          pacient: waitingPatient.selectedTicket,
          dateInitRoom: waitingPatient.dateInitRoom,
          leavingRoom: waitingPatient.leavingRoom,
          inRoom: waitingPatient.inRoom,
        })
        // this.props.saveSelectedTicket(waitingPatient.selectedTicket)
      }
    }
  }

  componentDidUpdate(oldProps) {
    if (this.props.notification.length && this.props.notification !== oldProps.notification) {
      const audio = this.audioNotification
      if (audio) {
        audio.play()
      }
    }
  }

  componentWillUnmount() {
    // clearInterval(this.callTimer);
  }

  handleOpenChat = () => {
    this.setState({
      inRoom: true,
      isVideo: false,
      doctor: this.props.auth.userdata.name,
      doctorName: this.props.auth.userdata.name,
    })

    const waitingPatient = {
      doctor: this.state.doctor,
      doctorName: this.state.doctorName,
      leavingRoom: this.state.leavingRoom,
      isVideo: this.state.isVideo,
      pacient: this.props.selectedTicket,
      inRoom: true,
      dateInitRoom: new Date(),
    }
    localStorage.setItem('waitingPatient', JSON.stringify(waitingPatient));
  }

  handleCloseChat = () => {
    this.setState({
      inRoom: false,
      isVideo: false,
      doctor: undefined,
      doctorName: undefined,
      selectedCardId: ''
    })

    this.props.saveSelectedTicket(undefined);
    localStorage.removeItem('waitingPatient')
  }

  cleanDropdown = () => {
    this.setState({
      dropdown: {
        isActive: false
      }
    });
  };

  clearStatusModal = () => {
    this.setState({
      statusModal: {
        isActive: false,
        pacient: {}
      }
    });
  };

  handleSetSelectedCardId = ticket => {
    this.setState({ selectedCardId: ticket && ticket.id })
    this.props.saveSelectedTicket(ticket);

    if (ticket) {
      this.props.getTicketsByPatient(ticket);
    }
  }

	renderHeader = () => {
    return (
      <Header
        hiddenButtons={this.state.leavingRoom || this.state.inRoom}
        applyFilterTicketsThunk={this.props.applyFilterTicketsThunk}
        history={this.props.history}
        small={this.props.auth.userdata.profile === 'physician'}
        startFirstFetching={this.props.startFirstFetching}
        saveSelectedTicket={this.props.saveSelectedTicket}
      />
    );
  }

  renderPatientDetails = () => (
    <Sidebar
      inRoom={this.state.inRoom}
      leavingRoom={this.state.leavingRoom}
      doctor={this.props.auth.userdata.name}
      doctorName={this.props.auth.userdata.name}
      userType={this.props.auth.userdata.profile}
      handleOpenChat={this.handleOpenChat}
      history={this.props.history}
      leaveRoom={this.leaveRoom}
      handleCloseChat={this.handleCloseChat}
      isInRoom={this.state.leavingRoom || this.state.inRoom}
      pacient={this.props.selectedTicket}
      leaveAfterAppointment={this.leaveAfterAppointment}
    />
  );

  renderRoom = () => {
    return (
      <Room
        leaveRoom={this.leaveRoom}
        history={this.props.history}
        doctorName={this.state.doctorName}
        doctor={this.state.doctor}
        isVideo={this.state.isVideo}
        pacient={this.props.selectedTicket}
        handleCloseChat={this.handleCloseChat}
      />
  )};

  backToRoom = () => {
    this.setState({
      inRoom: true,
      leavingRoom: false,
    })
    const waitingPatient = {
      doctor: this.state.doctor,
      doctorName: this.state.doctorName,
      isVideo: this.state.isVideo,
      pacient: this.props.selectedTicket,
      inRoom: true,
      leavingRoom: false,
      dateInitRoom: new Date(),
    }
    localStorage.setItem('waitingPatient', JSON.stringify(waitingPatient));
  }

  leaveRoom = () => {
    this.setState({
      inRoom: false,
      leavingRoom: true,
    })

    const waitingPatient = {
      doctor: this.state.doctor,
      doctorName: this.state.doctorName,
      inRoom: false,
      leavingRoom: true,
      isVideo: this.state.isVideo,
      pacient: this.props.selectedTicket,
      dateInitRoom: new Date(),
    }
    localStorage.setItem('waitingPatient', JSON.stringify(waitingPatient));
  }

  leaveAfterAppointment = () => {
    this.setState({
      inRoom: false,
      leavingRoom: false,
    })
    localStorage.removeItem('waitingPatient')
    this.props.saveSelectedTicket(undefined)
  }

  renderOuterRoom = () => {
    const { firstFetching, isFetching } = this.props;

    if (this.state.leavingRoom) {
      return (
        <AfterAppointment
          pacient={this.props.selectedTicket}
          doctorName={this.props.auth.userdata.name}
          userType={this.props.auth.userdata.profile}
          backToRoom={this.backToRoom}
          leaveAfterAppointment={this.leaveAfterAppointment}
          closeTicketThunk={this.props.closeTicketThunk}
          updateTicketThunk={this.props.updateTicketThunk}
          endCallSendEmailThunk={this.props.endCallSendEmailThunk}
          satisfactionSurveyThunk={this.props.satisfactionSurveyThunk}
        />
      )
    }
    if (firstFetching && isFetching) {
      return <Placeholder />
    }
    return (
      <PatientCardList
        showFilteredDates={this.props.selectedFilters[0].selected !== 'Aguardando teleconsulta'}
        selectedCardId={this.state.selectedCardId}
        handleSetSelectedCardId={this.handleSetSelectedCardId}
        userType={this.props.auth.userdata.profile}
      />
    )
  }

  render() {
    return (
      <Container>
        {this.renderHeader()}
				<ContainerBox>
					{this.renderPatientDetails()}
          { this.state.inRoom
            ? this.renderRoom()
            : this.renderOuterRoom()
          }
          {this.props.notification.length > 0 && (
            <PopupNotifcation onClick={() => this.props.toggleNotification([])}>
              <h3>Novo atendimento</h3>
              <p>Um novo atendimento foi registrado na fila.</p>
            </PopupNotifcation>
          )}
          <audio ref={node => this.audioNotification = node}>
            <source src={sound1} type="audio/ogg"></source>
            <source src={sound2} type="audio/mp3"></source>
          </audio>
				</ContainerBox>
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
