import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { format, isAfter, set, add, sub, isBefore } from 'date-fns'
import TextareaAutosize from 'react-textarea-autosize'
import ReactScrollbar from 'react-scrollbar'

// Components
import CallHeader from '../../components/call-header'
import ChangeStatus from '../../components/change-status'
import PatientHistory from '../../components/sidebar/patient-history'
import Placeholder from '../../components/sidebar/placeholder'

// Images
import warning from '../../assets/info.svg'
import historyIcon from '../../assets/history_icon.svg'
import noteIcon from '../../assets/notes_icon.svg'
import perfil from '../../assets/perfil_icon.svg'
import health from '../../assets/health-icon.svg'
import arrow from '../../assets/arrow_white.svg'
import folhas from '../../assets/folhas.svg'
import edit from '../../assets/edit-icon.svg'
import deleteIcon from '../../assets/close-blue.svg'
import editHover from '../../assets/edit-icon_hover.svg'
import arrowDown from '../../assets/arrow-down.svg'

// Redux
import {
  getNotesByPatient,
  createNote,
  removeNote,
  checkCardState,
} from '../../dataflow/thunks/dashboard-thunks'

const mapStateToProps = (state) => ({
  selectedTicket: state.dashboard.selectedTicket,
  selectedFilters: state.dashboard.selectedFilters,
  isFetchingSidebarTab: state.dashboard.isFetchingSidebarTab,
  notes: state.dashboard.notes,
})

const mapDispatchToProps = (dispatch) => ({
  getNotesByPatient: (cpf) => dispatch(getNotesByPatient(cpf)),
  createNote: (cpf) => dispatch(createNote(cpf)),
  removeNote: (info) => dispatch(removeNote(info)),
  checkCardState: (info) => dispatch(checkCardState(info)),
})

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 23rem;
  height: 100%;
  margin-top: 0.875rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0 0.1875rem 0.625rem #00000017;
  border-radius: 0.25rem;
  z-index: 2;

  .scrollarea {
    width: 100%;
  }

  .scrollarea .scrollbar-container {
    opacity: 0.12;
  }

  .scrollarea:hover .scrollbar-container {
    opacity: 0.22;
  }

  .scrollarea .scrollbar-container.active,
  .scrollarea .scrollbar-container:hover {
    opacity: 0.4 !important;
  }

  .scrollarea .scrollbar-container.vertical {
    width: ${(props) => props.scrollWidth || '0.5rem'};
    height: 100%;
    right: 0.125rem;
    top: 0;
    border-radius: 0.375rem;
    z-index: 3;
  }

  .scrollarea .scrollbar-container.vertical .scrollbar {
    width: ${(props) => props.scrollWidth || '0.5rem'};
    height: 1.25rem;
    background: ${(props) => props.barColor || '#777777'};
    margin-left: 0;
    border-radius: 0.375rem;
  }
`

const Content = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 1rem 0.75rem 1rem;
`

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto 1.5rem;
`

const ContainerStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const StatusTextBox = styled.span`
  display: flex;
  align-items: center;
`

const StatusIcon = styled.img`
  width: 1.5rem;
  margin-right: 0.5rem;
`

const StatusText = styled.p`
  margin: 0;
  font: 400 0.9375rem 'Open Sans', sans-serif;
`

const ContainerDetails = styled.div`
  position: relative;
  width: calc(100% - 1.5rem);
  margin: 0 auto;
  border-bottom: ${(props) =>
    !props.partial && `solid .03125rem ${props.color}`};
`

const BoxDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => !props.partial && '1rem'};
  padding: 0 0.75rem;
`

const BoxIcon = styled.div`
  margin: 1rem 0;
  ${({ boxWidth }) =>
    boxWidth
      ? css`
          width: 60%;
        `
      : css`
          width: 40%;
          p {
            margin: 0;
          }
        `}
`

const Details = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ altShow }) =>
    altShow &&
    css`
      display: none;
    `}
`

const Name = styled.p`
  width: calc(100% + 1.5rem);
  padding: 0.5rem 0.75rem;
  margin: 0.75rem -0.75rem 0;
  font: 600 1.25rem 'Open Sans', sans-serif;
  text-transform: capitalize;
  background: #f6f9fc;
  border-radius: 0.3125rem;
  -webkit-backdrop-filter: blur(0.25rem);
  backdrop-filter: blur(0.25rem);
  -webkit-backdrop-filter: blur(0.25rem);
`

const Cpf = styled.p`
  margin: 0;
  margin-bottom: ${(props) => props.first && '0.5rem'};
  margin-right: 1.25rem;
  font: ${(props) => (props.semiBold ? '700 .875rem' : '400 .75rem')}
      'Open Sans',
    sans-serif;
  color: #333333;

  ${({ altShow }) =>
    altShow &&
    css`
      display: none;
    `}
`

const ContainerSymptoms = styled.div`
  width: 100%;
  padding: 1.5rem 0 0;
`

const Title = styled.p`
  text-align: left;
  font: 300 0.75rem 'Open Sans', sans-serif;
  letter-spacing: 0;
  color: #0061af;
  padding-left: 0.75rem;
  margin-bottom: ${(props) => props.marginBottom};
`

const SymptomsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.75rem;
  list-style: none;
`

const SymptomItem = styled.li`
  display: flex;
  width: ${(props) => (props.othersSymptoms ? '100%' : '50%')};
  margin-bottom: 0.5rem;
  margin-top: ${(props) => props.othersSymptoms && '1rem'};
`

const SymptomLabel = styled.p`
  width: ${(props) => props.othersSymptoms && '100%'};
  margin: 0;
  padding: 0.5rem 0.75rem;
  border: 0.0625rem solid
    ${(props) =>
      props.isSelected || props.othersSymptoms ? '#005FB3' : 'transparent'};
  border-radius: 0.3125rem;
  font: 600 0.75rem 'Opens Sans', sans-serif;
  color: #005fb3;
  opacity: ${(props) => (props.isSelected ? '1' : '.3')};
  background: ${(props) => props.isSelected && '#F6F9FC'};
`

const SymptomsInput = styled.input`
  width: 100%;
  margin: 0.5rem 0;
  border: none;
  outline: none;
  font: 400 0.75rem 'Open Sans', sans-serif;
`

const ContainerOthersDetails = styled.div`
  width: 100%;
  padding: 1rem 0 0;
`

const OthersDetailsTitle = styled.h2`
  text-align: left;
  font: 300 0.75rem 'Open Sans', sans-serif;
  letter-spacing: 0;
  color: #0061af;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
`

const OthersDetailsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  /* margin: 0 -.5rem; */
  ${({ i }) =>
    i === 0 &&
    css`
      justify-content: space-between;
    `}
`

const OutherDetailsItem = styled.li`
  display: flex;
  width: auto;
  width: ${(props) => `calc(${props.width} - 2%)`};
  margin: 1%;
`

const OutherDetailsLabel = styled.p`
  width: 100%;
  margin: 0;
  padding: 0.5rem 0.75rem;
  border: 0.0625rem solid #005fb3;
  border-radius: 0.3125rem;
  text-align: center;
  font: 600 0.75rem 'Opens Sans', sans-serif;
  color: #005fb3;
  background: #f6f9fc;
`

const SidebarList = styled.div`
  width: 5rem;
  height: 100%;
  background: #f6f9fc;
  margin-top: 0.875rem;
  z-index: 2;
`

const Option = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 5rem;
  margin: 0 0 0.5rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  cursor: ${(props) => !props.disabled && 'pointer'};
  ${({ selected }) =>
    selected &&
    css`
      box-shadow: 0 0.1875rem 0.375rem #00000029;
      border-radius: 0.25rem;
    `}
  :hover {
    img,
    p {
      opacity: ${(props) => !props.disabled && '1'};
    }
  }
`

const OptionIcon = styled.img`
  width: 1.5rem;
  opacity: ${(props) => (props.selected ? 1 : 0.4)};
  transition: ${(props) => !props.disabled && 'opacity 0.2s'};
`

const OptionLabel = styled.p`
  font: 500 0.75rem Open Sans;
  color: #0561af;
  opacity: ${(props) => (props.selected ? 1 : 0.4)};
  transition: ${(props) => !props.disabled && 'opacity 0.2s'};
`

const EmailInput = styled.input`
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.25rem 0.75rem;
  background: #f6f9fc 0% 0% no-repeat padding-box;
  border: 0.03125rem solid #0061af;
  border-radius: 0.1875rem;
  font: 300 0.875rem Open Sans;
  resize: none;
  ::placeholder {
    font-style: italic;
    color: #0061af;
  }
`

const EmailTextArea = styled(TextareaAutosize)`
  display: flex;
  width: 100%;
  height: 50vh;
  max-height: 31.25rem;
  padding: 0.75rem;
  background: #f6f9fc 0% 0% no-repeat padding-box;
  border: 0.03125rem solid #0061af;
  border-radius: 0.1875rem;
  font: 300 0.875rem Open Sans;
  resize: initial;
  ::placeholder {
    font-style: italic;
    color: #0061af;
  }
`

const EmailButton = styled.button`
  width: 100%;
  margin-top: 1rem;
  padding: 0.75rem 1rem;
  text-align: center;
  font: 700 1rem 'Open Sans', sans-serif;
  color: #ffffff;
  background: #00afef 0% 0% no-repeat padding-box;
  border: 0.03125rem solid #f2f2f2;
  border-radius: 0.1875rem;
`

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 28rem;
  height: 100%;
  padding: 0 0;
  margin-top: 0.875rem;
  background: transparent
    linear-gradient(180deg, #ffffff 0%, #f6f9fc 51%, #f6f9fc 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0 0.1875rem 0.625rem #00000017;
  border-radius: 0.25rem;
`

const WelcomeTitle = styled.h2`
  text-align: center;
  font: 700 1.375rem 'Open Sans', sans-serif;
  color: #0061af;
`

const WelcomeText = styled.p`
  width: 50%;
  margin-top: 1.25rem;
  text-align: center;
  font: 300 0.875rem 'Open Sans', sans-serif;
  color: #75797c;
`

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  z-index: 3;
`

const Button = styled.button`
  position: relative;
  width: 100%;
  padding: 1rem;
  background: ${(props) => (props.disabled ? '#cecece' : '#00AFEF')};
  box-shadow: 0 0.1875rem 0.375rem #00000029;
  border-radius: 0 0 0.1875rem 0.1875rem;
  border: none;
  color: #ffffff;
  font: 400 0.95rem 'Open Sans', sans-serif;
  cursor: ${(props) => !props.disabled && 'pointer'};
  outline: none;
`

const Icon = styled.img`
  position: absolute;
  top: 1.25rem;
  right: 1.875rem;
  width: 0.875rem;
`

const WelcomeIcon = styled.img`
  width: 60%;
`

const HealthBox = styled.div`
  position: relative;
  padding: 0.5rem 1rem;
  text-align: left;
  font: 600 0.75rem 'Open Sans', sans-serif;
  color: #005fb3;
  background: #f6f9fc 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #005fb3;
  border-radius: 0.3125rem;
  margin-bottom: 0.5rem;
`

const ContactBox = styled.div`
  position: relative;
`

const ContactLabel = styled.div`
  position: relative;
  padding: 0.5rem 1rem;
  text-align: left;
  font: 600 0.75rem 'Open Sans', sans-serif;
  color: #005fb3;
  background: #f6f9fc 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #005fb3;
  border-radius: ${(props) => (props.showContacts ? '0.3125rem 0.3125rem 0 0;' : '0.3125rem')};
  display: flex;
  justify-content: space-between;
  width: calc(100% + 1.5rem);
  margin: 0.75rem -0.75rem 0;
`

const ContactList = styled.div`
  position: absolute;
  border: 0.0625rem solid #005fb3;
  border-radius: 0 0 0.3125rem 0.3125rem;
  box-shadow: 0px 3px 6px #00000026;
  margin-bottom: 0.5rem;
  background: white;
  z-index: 1;
  width: calc(100% + 1.5rem);
  margin: 0 -0.75rem;
`
const Contact = styled.div`
  padding: 0.55rem 1rem;
  text-align: left;
  font: 600 0.75rem 'Open Sans', sans-serif;
  display: flex;
  justify-content: space-between;
`

const HealthBoxAlt = styled.div`
  min-height: 6.25rem;
  padding: 0.5rem 1rem;
  background: #f6f9fc 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #005fb3;
  border-radius: 0.3125rem;
  margin-bottom: 1.25rem;
`

const HealthText = styled.p`
  text-align: left;
  font: 600 0.75rem 'Open Sans', sans-serif;
  color: ${(props) => props.color};
`

const NoteButton = styled.button`
  position: relative;
  width: 100%;
  padding: 0.5rem;
  border: 0.03125rem solid #0061af;
  border-radius: 0.1875rem;
  background: #fff;
  text-align: center;
  font: 400 0.8125rem 'Open Sans', sans-serif;
  color: #0061af;
  cursor: pointer;

  :hover {
    color: #7800af;
    border-color: #7800af;
    transition: color 0.2s;

    img {
      content: url(${editHover});
    }
  }
`

const NoteText = styled.div`
  position: relative;
  width: 100%;
  min-height: 4rem;
  margin-top: 0.75rem;
  padding: 0.75rem;
  padding-right: 1.25rem;
  font: italic 400 0.875rem 'Open Sans', sans-serif;
  letter-spacing: 0;
  color: #333333;
  background: #f6f9fc 0% 0% no-repeat padding-box;
  border: 0.03125rem solid #0061af;
  border-radius: 0.1875rem;
  word-break: break-word;

  :hover {
    button {
      display: block;
    }
  }
`

const NoteTextEdit = styled(TextareaAutosize)`
  display: flex;
  align-items: flex-start;
  width: 100%;
  min-height: 4rem;
  margin-top: 0.75rem;
  padding: 0.75rem;
  background: #e3ecf7 0% 0% no-repeat padding-box;
  border: 0.03125rem solid #7800af;
  border-radius: 0.1875rem;
  font: italic 400 0.875rem 'Open Sans', sans-serif;
  resize: initial;
  outline: none;
  ::placeholder {
    font-style: italic;
  }
`

const DeleteNote = styled.button`
  display: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`

const NoteIcon = styled.img`
  position: absolute;
  top: 0.625rem;
  right: 1.875rem;
  width: 0.75rem;
`

const ErrorMessage = styled.span`
  width: 100%;
  min-height: 4rem;
  margin-top: 0.75rem;
  padding: 0.75rem;
  padding-right: 1.25rem;
  font: italic 400 0.875rem 'Open Sans', sans-serif;
  letter-spacing: 0;
  color: #333333;
  background: #f6f9fc 0% 0% no-repeat padding-box;
  border: 0.03125rem solid #0061af;
  border-radius: 0.1875rem;
  word-break: break-word;
`

const DeleteIcon = styled.img``

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [
        {
          label: 'Perfil',
          icon: perfil,
          disabled: false,
        },
        {
          label: 'Histórico',
          icon: historyIcon,
          disabled: false,
        },
        {
          label: 'Notas',
          icon: noteIcon,
          disabled: false,
        },
        {
          label: 'Saúde',
          icon: health,
          disabled: false,
        },
      ],
      selectedTab: 'Perfil',
      symptoms: [
        'Febre',
        'Tosse',
        'Dor',
        'Dor de garganta',
        'Coriza',
        'Espirros',
        'Mal estar geral',
        'Falta de ar',
        'Cansaço acima do normal',
        'Dificuldade de respirar',
        'Outros sintomas?',
      ],
      newNote: true,
      newNoteText: '',
      error: undefined,
    }
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldProps.selectedTicket !== this.props.selectedTicket && oldState.error) {
      this.setState({
        error: undefined,
      })
    }
  } 

  handleOpenNewNote = () => {
    let { newNote, newNoteText } = this.state

    if (newNoteText && newNoteText.length > 0) {
      newNote = true
      newNoteText = undefined
    } else {
      newNote = !newNote
    }
    this.setState({
      newNote,
      newNoteText,
    })
  }

  handleTextNote = (ev) => {
    this.setState({
      newNoteText: ev.target.value,
    })
  }

  handleDeleteNote = (note) => {
    this.props.removeNote(note.id)
  }

  handleSaveNewNote = () => {
    const { doctorName, pacient } = this.props
    const { newNoteText } = this.state
    const { cpf } = pacient.ticket

    const note = {
      text: newNoteText,
      doctorName,
      cpf,
    }

    if (newNoteText && newNoteText.length) {
      this.props.createNote(note)
    }

    this.setState({
      newNoteText: 'false',
      newNote: false,
    })
  }

  choosePacientRiskLabel = (risk) => {
    switch (risk) {
      case 'cenario_a':
        return 'baixa'
      case 'cenario_b':
        return 'média'
      case 'cenario_c':
        return 'alta'
      default:
        return risk
    }
  }

  showClient = (client) => {
    if (client === 'cliente_nubank') {
      return 'NuBank'
    }
    if (client === 'cliente_itau') {
      return 'Itaú'
    }
    if (client === 'general-practice') {
      return 'ADMA HEALTH'
    }
    return 'HSL'
  }

  choosePacientStatusLabel = (status) => {
    const texts = {
      encaminhado_pa: 'Encaminhado PA',
      aguardando_atendimento: 'Aguardando abertura',
      finalizado: 'Atendimento finalizado',
      aguardando_teleconsulta: 'Aguardando teleconsulta',
      em_atendimento: 'Em Atendimento',
    }

    for (let i = 1; i <= 15; i++) {
      texts[`${i}_dia${i > 1 ? 's' : ''}`] = `Retorno em ${i} dia${
        i > 1 ? 's' : ''
      }`
    }

    const labels = status.map((item) => {
      return texts[item]
    })

    if (labels.includes('Em Atendimento')) {
      return 'Em Atendimento'
    }
    return labels.length > 0 ? labels.join(', ') : 'Aguardando teleconsulta'
  }

  chooseColor = (risk) => {
    switch (risk) {
      case 'media':
        return '#7800AF'
      case 'alta':
        return '#AF0000'
      case 'baixa':
      default:
        return '#00AFEF'
    }
  }

  getWidth = (string) => {
    const width = string.length
    if (width <= 15) {
      return '33.33%'
    }
    if (width > 15 && width <= 50) {
      return '66.66%'
    }
    return '100%'
  }

  renderHeader = () => {
    const { pacient } = this.props

    const user = pacient || null
    const risk = this.choosePacientRiskLabel(user && user.risks)

    return (
      <Header>
        <ContainerStatus>
          <StatusText>{user && user.date}</StatusText>
          <StatusTextBox>
            {risk === 'Alta' && <StatusIcon src={warning} />}
            <StatusText icon risk={risk === 'Alta'}>
              {risk} prioridade
            </StatusText>
          </StatusTextBox>
        </ContainerStatus>
      </Header>
    )
  }

  calcAge = (dateString) => {
    const birthday = +new Date(dateString)
    return ~~((Date.now() - birthday) / 31557600000)
  }

  treatData = (data) => {
    if (data && data.length > 0) {
      return data.split('";"').map((i) => {
        const items = i.split(':').map((a) => a.replace('"', ''))
        const key = items[0].replace('"', '')
        const valors = items
          .filter((a) => a.replace('"', '') !== key)
          .map((a) => a.split(';').map((b) => b.replace('"', '')))
          .flat(Infinity)
        return { key, valors }
      })
    }
  }

  handleShowContacts = () => {
    this.setState({
      showContacts: !this.state.showContacts,
    })
  }

  handlePhone = (number) => {
    if (number) {
      let v = ''
      if (number) {
        v = number.replace(/[^0-9]+/g, '')
        v = v.replace(/^(\d{2})(\d)/g, '($1) $2')
        v = v.replace(/(\d{5})(\d)/g, '$1-$2')
      }
      return v
    }
    return ''
  }

  renderPacientDetails = () => {
    const { pacient } = this.props
    const { emergencyContacts } = this.props.pacient.ticket
    const birthday = format(new Date(pacient.ticket.birthday), 'dd/MM/yyyy')
    const age = this.calcAge(new Date(pacient.ticket.birthday))
    const contacts = emergencyContacts ? emergencyContacts : []
    
    return (
      <ContainerDetails color={this.chooseColor(pacient.priority)}>
        <BoxDetails>
          <Name>{pacient.ticket.name}</Name>
          <Details>
            <BoxIcon boxWidth>
              <Cpf semiBold first>
                {age} anos
              </Cpf>
              <Cpf altShow={this.state.selectedTab !== 'Perfil'}>
                nascido em {birthday}
              </Cpf>
            </BoxIcon>
            <BoxIcon>
              <Cpf semiBold first>
                Cliente {this.showClient(pacient.ticket.type)}
              </Cpf>
              <Cpf />
            </BoxIcon>
          </Details>
          <Details altShow={this.state.selectedTab !== 'Perfil'}>
            <BoxIcon boxWidth>
              <Cpf semiBold first>
                {pacient.ticket.gender === 'masculino' ? 'Homem' : 'Mulher'}
              </Cpf>
              {pacient.ticket.cpf !== 'RNE' && (
                <Cpf semiBold first>
                  CPF: {pacient.ticket.cpf}
                </Cpf>
              )}
              {pacient.ticket.email && (
                <Cpf semiBold>{pacient.ticket.email}</Cpf>
              )}
            </BoxIcon>
            <BoxIcon>
              {pacient.ticket.city && pacient.ticket.city !== 'undefined' && (
                <Cpf semiBold first>
                  {pacient.ticket.city}
                </Cpf>
              )}
              <Cpf semiBold>{this.handlePhone(pacient.ticket.cellphone)}</Cpf>
            </BoxIcon>
          </Details>
        {contacts.length > 0 && (
          <>
            <ContactBox onClick={this.handleShowContacts}>
              <ContactLabel showContacts={this.state.showContacts}>
                Contato de emergência
                <img src={arrowDown}></img>
              </ContactLabel>
              {this.state.showContacts && (
                <ContactList>
                  {contacts.map(contact => (
                    <Contact key={contact.contactId}>
                      <p>{contact.name}</p>
                      <p>{this.handlePhone(contact.contact)}</p>
                    </Contact>
                  ))}
                </ContactList>
              )}
            </ContactBox>
          </>
        )}
        </BoxDetails>
      </ContainerDetails>
    )
  }

  renderPacientHistory = () => {
    const { pacient } = this.props
    const { history } = this.props.selectedTicket
    return (
      <PatientHistory
        history={history}
        pacient={pacient}
        chooseColor={this.chooseColor}
        choosePacientRiskLabel={this.choosePacientRiskLabel}
        treatData={this.treatData}
        isFetchingSidebarTab={this.props.isFetchingSidebarTab}
        selectedTicket={this.props.selectedTicket}
      />
    )
  }

  renderSymptoms = () => {
    const { symptoms } = this.state
    const { pacient } = this.props
    const { ticket } = pacient
    const syntoms = ticket.symptoms || []
    const selectedSymptoms = syntoms.concat(ticket.other_symptoms || [])

    if (syntoms.length > 0) {
      return (
        <ContainerSymptoms>
          <Title>Sintomas</Title>
          <SymptomsList>
            {symptoms.map((item) => {
              const isSelected = selectedSymptoms.find(
                (symptom) => symptom === item
              )
              const othersSymptoms = item === 'Outros sintomas?'
              const selectedOthers = ticket.other_syntoms === 'Sim'

              return (
                <SymptomItem key={item} othersSymptoms={othersSymptoms}>
                  <SymptomLabel
                    isSelected={
                      isSelected || (othersSymptoms && selectedOthers)
                    }
                    othersSymptoms={othersSymptoms}
                  >
                    {item}
                    {othersSymptoms && (
                      <SymptomsInput
                        readOnly
                        value={selectedOthers ? ticket.other_syntoms_text : ''}
                      />
                    )}
                  </SymptomLabel>
                </SymptomItem>
              )
            })}
          </SymptomsList>
        </ContainerSymptoms>
      )
    }

    return null
  }

  renderPacientOthersDetails = () => {
    try {
      const { questionnaire } = this.props.pacient
      const currentQuestions = []
      const questionsWithCategories = []
      const categories = []

      Object.keys(questionnaire).forEach((item) => {
        if (item !== 'createdAt' && item !== 'updatedAt' && item !== 'userId') {
          if (questionnaire[item]) {
            const question = JSON.parse(questionnaire[item])

            if (question.category) {
              if (!categories.includes(question.category)) {
                categories.push(question.category)
              }
              questionsWithCategories.push(question)
            }

            if (!question.category) {
              currentQuestions.push(question)
            }
          }
        }
      })
      categories.map((category) => {
        const currentCategory = questionsWithCategories.filter(
          (item) => item.category === category
        )
        const objectCategory = {
          label: category,
          category,
          answer: currentCategory.map((item) => {
            return {
              value: item.answer,
              kind: item.kind,
            }
          }),
        }
        currentQuestions.push(objectCategory)
        return null
      })

      return currentQuestions.map((attr, index) => {
        const answers =
          typeof attr.answer === 'string' ? attr.answer.split(';') : attr.answer
        return (
          <ContainerOthersDetails key={index}>
            <OthersDetailsTitle>
              {attr.category || attr.label}
            </OthersDetailsTitle>
            <OthersDetailsList i={index}>
              {answers.map((data, index) => (
                <OutherDetailsItem
                  key={index}
                  width={this.getWidth(
                    typeof attr.answer === 'string'
                      ? data || 'Nenhuma'
                      : `${data.value} ${data.kind}` || 'Nenhuma'
                  )}
                >
                  <OutherDetailsLabel>
                    {typeof attr.answer === 'string'
                      ? data || 'Nenhuma'
                      : `${data.value} ${data.kind}` || 'Nenhuma'}
                  </OutherDetailsLabel>
                </OutherDetailsItem>
              ))}
            </OthersDetailsList>
          </ContainerOthersDetails>
        )
      })
    } catch (err) {
      console.log('err', err)
    }
  }

  renderEmail = () => (
    <ContainerSymptoms>
      <EmailInput
        type='email'
        value={this.props.pacient.ticket.email || ''}
        disabled
      />
      <EmailTextArea placeholder='Digite aqui o email com orientações ao paciente...' />
      <EmailButton>Enviar email</EmailButton>
    </ContainerSymptoms>
  )

  renderHealth = () => {
    const { trial, newStatus } = this.props.pacient.ticket
    const treatData = this.treatData(trial)
    let state
    let feeling

    if (trial && treatData) {
      state = treatData.find((i) => i.key === 'Estado')
      feeling = treatData.filter((i) => i.key !== 'Estado')
    }

    return (
      <ContainerSymptoms>
        {trial && treatData && (
          <>
            <Title marginBottom='1rem'>Triagem digital:</Title>
            {state && (
              <HealthBox>{state.valors}</HealthBox>
            )}
            {feeling && (
              <HealthBoxAlt>
                <HealthText color='#005FB3'>O que está sentindo:</HealthText>
                {feeling.length ? (
                  feeling.map((a) =>
                    a.valors.map((data) => <HealthText>"{data}"</HealthText>)
                  )
                ) : (
                  <HealthText>Não descrito pelo paciente</HealthText>
                )}
              </HealthBoxAlt>
            )}
          </>
        )}
        <Title marginBottom='0.5rem'>Status do paciente:</Title>
        <HealthBox>
          {newStatus}
          {!this.props.isInRoom && (
            <ChangeStatus
              roomId={this.props.pacient.id}
              history={this.props.history}
              pacient={this.props.pacient}
              handleCloseChat={this.props.handleCloseChat}
              doctorName={this.props.doctorName}
              leaveAfterAppointment={this.props.leaveAfterAppointment}
            />
          )}
        </HealthBox>
      </ContainerSymptoms>
    )
  }

  renderPacientNotes = () => {
    const { notes, isFetchingSidebarTab } = this.props
    const { newNote } = this.state

    const notesOrdered = notes.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt)
    })

    if (isFetchingSidebarTab) {
      return <Placeholder type='notes' />
    }

    return (
      <ContainerSymptoms>
        <NoteButton onClick={this.handleOpenNewNote}>
          Adicionar nota
          <NoteIcon src={edit} />
        </NoteButton>
        {newNote && (
          <NoteTextEdit
            placeholder='Digite aqui sua anotação...'
            onChange={this.handleTextNote}
            onBlur={this.handleSaveNewNote}
            autoFocus
          />
        )}
        {notesOrdered.map((note, index) => (
          <NoteText key={index}>
            {note.text}
            <DeleteNote onClick={() => this.handleDeleteNote(note)}>
              <DeleteIcon src={deleteIcon} />
            </DeleteNote>
          </NoteText>
        ))}
      </ContainerSymptoms>
    )
  }

  handleSelectTab = (label) => {
    this.setState({ selectedTab: label })
    this.scrollTop()
  }

  renderSidebar = () => {
    const { list, selectedTab } = this.state

    return (
      <SidebarList>
        {list.map((item) => (
          <Option
            key={item.label}
            selected={item.label === selectedTab}
            disabled={item.disabled}
            onClick={() => {
              if (!item.disabled) {
                this.handleSelectTab(item.label)
              }
            }}
          >
            <OptionIcon
              src={item.icon}
              selected={item.label === selectedTab}
              disabled={item.disabled}
            />
            <OptionLabel
              selected={item.label === selectedTab}
              disabled={item.disabled}
            >
              {item.label}
            </OptionLabel>
          </Option>
        ))}
      </SidebarList>
    )
  }

  renderTab = () => {
    switch (this.state.selectedTab) {
      case 'Histórico':
        return this.renderPacientHistory()
      case 'Notas':
        return this.renderPacientNotes()
      case 'Email':
        return this.renderEmail()
      case 'Saúde':
        return (
          <>
            {this.renderSymptoms()}
            {this.renderPacientOthersDetails()}
          </>
        )
      case 'Perfil':
      default:
        return this.props.pacient.ticket.type === 'general-practice' ? (
          this.renderHealth()
        ) : (
          <>
            {this.renderSymptoms()}
            {this.renderPacientOthersDetails()}
          </>
        )
    }
  }

  renderWelcome = () => (
    <WelcomeContainer>
      <WelcomeIcon src={folhas} />
      <WelcomeTitle>
        {this.props.doctorName
          ? `Olá, ${this.props.doctorName.toLowerCase()}`
          : 'Bem-Vindo'}
      </WelcomeTitle>
      <WelcomeText>
        Selecione um paciente para iniciar o atendimento.
      </WelcomeText>
    </WelcomeContainer>
  )

  checkStatus = (selectedFilters) => {
    let validStatus = false
    selectedFilters.forEach((item) => {
      if (item.selected.includes('Retorno com Enfermeiro')) {
        validStatus = true
      }
    })
    return validStatus
  }

  validationOffice = () => {
    const startOffice = set(new Date(), { hours: 8, minutes: 0, seconds: 0 })
    const endOffice = set(new Date(), { hours: 20, minutes: 0, seconds: 0 })

    return isAfter(new Date(), startOffice) && isBefore(new Date(), endOffice)
  }

  validationAppointmentTime = (appointmentDate) => {
    const firstPeriod = sub(new Date(appointmentDate), { minutes: 10 })
    const finalPeriod = add(new Date(appointmentDate), { minutes: 10 })

    return isAfter(new Date(), firstPeriod) && isBefore(new Date(), finalPeriod)
  }

  handleNewCall = async (ticket) => {
    const cardStatus = await this.props.checkCardState(ticket.id)

    if (cardStatus.data.newStatus.toLowerCase() === 'em atendimento') {
      this.setState({
        error:
          'Ops! O paciente já está em atendimento com outro profissional. :)',
      })
      return
    } else if (cardStatus.data.newStatus.toLowerCase() === 'cancelado') {
      this.setState({
        error:
          'Ops! O paciente cancelou o atendimento. :)',
      })
      return
    }
    this.props.handleOpenChat()
  }

  scrollTop() {
    setTimeout(() => {
      this.scrollbar.scrollArea.scrollTop()
    }, 100)
  }

  renderButton = () => {
    const { ticket, newStatus } = this.props.pacient
    const { userType, selectedFilters, history } = this.props

    if (this.props.inRoom) {
      return (
        <CallHeader
          roomId={this.props.pacient.id}
          history={history}
          pacient={this.props.pacient}
          handleCloseChat={this.props.handleCloseChat}
          doctorName={this.props.doctorName}
          treatData={this.treatData}
          leaveRoom={this.props.leaveRoom}
        />
      )
    }

    if (userType === 'nurse') {
      const isFromReturnTab =
        selectedFilters[0].selected.includes('Retorno com Enfermeiro') ||
        selectedFilters[0].selected.includes('Encaminhado para Enfermeiro')

      if (isFromReturnTab) {
        return null
      }
    }

    const isWorkTime = this.validationOffice()

    let isInAppointmentTime = true

    if (userType !== 'nurse') {
      if (ticket.appointmentDate) {
        isInAppointmentTime = this.validationAppointmentTime(
          ticket.appointmentDate
        )
      }
    }

    const isInAttendance = newStatus === 'Em atendimento'

    let buttonDisabled = false

    if ((userType === 'nurse' && !isWorkTime) || !isInAppointmentTime || isInAttendance) {
      buttonDisabled = true
    }

    return (
      <ButtonContainer>
        {this.state.error && <ErrorMessage>{this.state.error}</ErrorMessage>}
        <Button
          disabled={buttonDisabled}
          onClick={() => !buttonDisabled && this.handleNewCall(ticket)}
        >
          Iniciar atendimento
          <Icon src={arrow} />
        </Button>
      </ButtonContainer>
    )
  }

  render() {
    const { userType, selectedFilters } = this.props
    return this.props.pacient ? (
      <>
        {this.renderSidebar()}
        <Container barColor='#94DCF8' scrollWidth='0.25rem'>
          {this.renderPacientDetails()}
          <ReactScrollbar
            speed={0.8}
            horizontal={false}
            smoothScrolling
            stopScrollPropagation
            height={this.state.selectedTab === 'Perfil' ? '60%' : '80%'}
            style={{
              marginBottom:
                (this.props.userType === 'physician' && !this.props.isAttend) ||
                (userType === 'nurse' &&
                  selectedFilters &&
                  this.checkStatus(selectedFilters))
                  ? 0
                  : '3.25rem',
            }}
            ref={(node) => {
              this.scrollbar = node
            }}
          >
            <Content>{this.renderTab()}</Content>
          </ReactScrollbar>
          {this.props.leavingRoom === false && this.renderButton()}
        </Container>
      </>
    ) : (
      this.renderWelcome()
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
