/* eslint-disable react/state-in-constructor */
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import EndCall from './end-call'
import PacientStatus from './pacient-status'
import EmailSended from './email_sended'

import {
  updateTicketThunk,
  closeTicketThunk,
  endCallThunk,
  endCallSendEmailThunk,
} from '../dataflow/thunks/dashboard-thunks'

const mapStateToProps = (state) => ({
  selectedTicket: state.dashboard.selectedTicket,
  userType: state.auth.userdata.profile,
})

const mapDispatchToProps = (dispatch) => ({
  updateTicketThunk: (ticket, status) =>
    dispatch(updateTicketThunk(ticket, status)),
  closeTicketThunk: (selectedTicket) => {
    dispatch(closeTicketThunk(selectedTicket))
  },
  endCallThunk: (roomId) => endCallThunk(roomId),
  endCallSendEmailThunk: (doctorName, pacient, status, emailNote) =>
    endCallSendEmailThunk(doctorName, pacient, status, emailNote),
})

// Styled
const Overlay = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.25rem);
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25rem;
  height: auto;
  margin: auto;
  padding: 2.875rem 2.875rem 1.875rem;
  border-radius: 0.25rem;
  background-color: #fff;
  box-shadow: 0 0 0.625rem #00000019;
`

const Title = styled.h2`
  margin-bottom: 1.25rem;
  text-align: center;
  font: 400 1.25rem Open Sans;
  letter-spacing: 0;
  color: #0061af;
`

const Text = styled.p`
  text-align: center;
  font: 500 1rem Open Sans;
  letter-spacing: 0;
  color: #131516;
  margin: 0 0 1.25rem;
`

const FirstButton = styled.button`
  width: 100%;
  margin: 1.25rem 0 0.75rem;
  padding: 0.875rem;
  background: #0061af 0% 0% no-repeat padding-box;
  border-radius: 0.375rem;
  text-align: center;
  font: 600 0.875rem Open Sans;
  letter-spacing: 0.02625rem;
  color: #ffffff;
  border: none;
`

const SecondButton = styled.button`
  width: 50%;
  margin: 0;
  padding: 0.875rem;
  background: none;
  text-align: center;
  font: 600 0.875rem Open Sans;
  text-decoration: underline;
  letter-spacing: 0.02625rem;
  color: #0061af;
  border: none;
  cursor: pointer;
  outline: none;
`

class Disconnect extends React.Component {
  state = {
    count: 300,
    isCount: false,
    step: 'initial',
    modal: {
      type: '',
      isActive: false,
    },
    selectedStatus: {},
    statusList: [
      {
        type: 'Aguardando teleconsulta',
        label: 'Aguardando teleconsulta',
      },
      {
        type: 'Encaminhamento PA',
        label: 'Encaminhamento PA',
      },
      {
        type: 'Retorno em 1 dia',
        label: 'Retorno em 1 dia',
      },
      {
        type: 'Retorno em 3 dias',
        label: 'Retorno em 3 dias',
      },
      {
        type: 'Finalizado',
        label: 'Finalizado',
      },
    ],
    emailText: '',
    selectedReturn: 1,
    isOpen: false,
  }

  componentDidMount() {
    if (this.props.type !== 'doctor') {
      this.startCount()
    }
  }

  startCount = () => {
    if (!this.state.counter) {
      this.setState({
        isCount: true,
        step: 'counter',
      })

      this.counter = setInterval(() => {
        if (this.state.count === 0) {
          clearInterval(this.counter)
          this.setState({
            isCount: false,
            count: 300,
            step: 'hasCounted',
          })
        } else {
          this.setState((prevState) => ({
            count: prevState.count - 1,
          }))
        }
      }, 1000)
    }
  }

  handleSelectedStatus = (status) => {
    this.setState({
      selectedStatus: status,
      isOpen: false,
    })
  }

  closeModal = () => {
    this.setState({
      selectedStatus: {},
      emailText: '',
      modal: {
        type: '',
        isActive: false,
      },
    })
  }

  openModal = (type) => {
    this.setState({
      modal: {
        type,
        isActive: true,
      },
    })
  }

  updateStatus = () => {
    const { roomId } = this.props

    const status = {
      status: this.state.selectedStatus.type,
    }

    if (
      this.state.selectedStatus.type === 'Encaminhado para Enfermeiro' ||
      this.state.selectedStatus.type === 'Retorno com Enfermeiro'
    ) {
      let date = new Date()
      date.setDate(date.getDate() + this.state.selectedReturn)

      date = date.toISOString()
      date = `${date.substring(0, date.lastIndexOf('.'))}-03:00`

      status.appointmentDate = date
    }

    if (this.state.selectedStatus.type) {
      this.openModal('finishConfirmation')
      if (this.state.selectedStatus.type === 'finalizado') {
        this.props.closeTicketThunk(roomId)
      } else {
        this.props.updateTicketThunk(this.props.selectedTicket, status)
      }

      if (this.state.emailText.length > 0) {
        this.props.endCallSendEmailThunk(
          this.props.doctorName,
          this.props.selectedTicket,
          this.state.selectedStatus,
          this.state.emailText
        )
      }
    }
  }

  handleEmailNoteText = (ev) => {
    this.setState({
      emailText: ev.target.value.trim(),
    })
  }

  saveReturnDate = (date) => {
    this.setState({
      selectedReturn: date,
    })
  }

  handleOpenSelect = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }))
  }

  redirectToLastScreen = () => {
    const { roomId } = this.props
    this.props.endCallThunk(roomId)
    this.props.history.replace({
      pathname: '/atendimento',
    })
    this.props.handleCloseChat()
  }

  renderEndCall = () => {
    const { modal } = this.state

    if (modal.isActive && modal.type === 'endCall') {
      return (
        <EndCall
          closeTicket={this.props.leaveRoom}
          closeModal={this.closeModal}
          pacient={this.props.pacient}
        />
      )
    }

    if (modal.isActive && modal.type === 'pacientStatus') {
      return (
        <PacientStatus
          handleSelectedStatus={this.handleSelectedStatus}
          selectedStatus={this.state.selectedStatus}
          statusList={this.state.statusList}
          closeModal={this.closeModal}
          pacient={this.props.pacient}
          updateStatus={this.updateStatus}
          saveReturnDate={this.saveReturnDate}
          handleEmailNoteText={this.handleEmailNoteText}
          emailText={this.state.emailText}
          isOpen={this.state.isOpen}
          selectedReturn={this.state.selectedReturn}
          userType={this.props.userType}
          handleOpenSelect={this.handleOpenSelect}
        />
      )
    }

    if (modal.isActive && modal.type === 'finishConfirmation') {
      return (
        <EmailSended
          closeModal={this.redirectToLastScreen}
          pacient={this.props.pacient}
        />
      )
    }

    return null
  }

  renderCountStep = () => {
    const displayTimer = () => {
      // Formatting the time into mm:ss
      const m = Math.floor((this.state.count % 3600) / 60)
      const s = Math.floor((this.state.count % 3600) % 60)

      return `${m < 10 ? '0' : ''}${m}:${s < 10 ? '0' : ''}${s}`
    }

    return (
      <>
        <Text alt>Reconectando... ({displayTimer()})</Text>
        <SecondButton
          onClick={() => {
            this.openModal('endCall')
          }}
        >
          Sair
        </SecondButton>
      </>
    )
  }

  renderPatientSteps = () => {
    const { step, isCount } = this.state
    if (step === 'counter' && isCount) {
      return this.renderCountStep()
    }

    if (step === 'hasCounted') {
      return (
        <>
          <Text>Deseja continuar?</Text>
          <FirstButton onClick={this.startCount}>Reconectar</FirstButton>
          <SecondButton
            onClick={() => {
              this.openModal('endCall')
            }}
          >
            Sair
          </SecondButton>
        </>
      )
    }

    return (
      <>
        <Text>Deseja continuar?</Text>
        <FirstButton onClick={this.startCount}>Ligar novamente</FirstButton>
        <SecondButton
          onClick={() => {
            this.openModal('endCall')
          }}
        >
          Sair
        </SecondButton>
      </>
    )
  }

  renderDoctorSteps = () => (
    <>
      <Text alt>Aguardando conexão</Text>
    </>
  )

  render() {
    return (
      <Overlay>
        <Container>
          <Title>
            {this.props.type !== 'doctor'
              ? 'O paciente perdeu a conexão :/'
              : 'Você está offline :('}
          </Title>
          {this.props.type !== 'doctor'
            ? this.renderPatientSteps()
            : this.renderDoctorSteps()}
        </Container>
        {this.renderEndCall()}
      </Overlay>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Disconnect)
