// Libs
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'

// Redux
import { logout } from '../dataflow/modules/authentication-module'
import { setInitialState } from '../dataflow/modules/dashboard-module'

// Images
import logo from '../assets/sirio-2.png'

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  setInitialState: () => dispatch(setInitialState()),
})

const Container = styled.div`
  width: 100%;
  height: 4.5rem;
  padding: 0 3rem 0 3.5rem;
  background: transparent
    linear-gradient(90deg, #0561af 0%, #00aced 38%, #00afef 100%);
  box-shadow: 0 0.1875rem 0.375rem #00000029;
  border-radius: 0.25rem;
  z-index: 3;
`

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`

const Logo = styled.img`
  margin-left: -2.5rem;
  margin-top: -0.375rem;
  cursor: pointer;
`

const LogoutButton = styled.button`
  height: 1.5rem;
  padding: 0 1.625rem;
  border: 0.0625rem solid #fff;
  border-radius: 1.25rem;
  background-color: transparent;
  color: #fff;
  font: 400 0.875rem 'Open Sans', serif;
  outline: none;
  cursor: pointer;
  transition: background 0.5s;

  :hover {
    background: #ffffff48 0% 0% no-repeat padding-box;
  }
`

const Options = styled.div``

const Option = styled.button`
  height: 4.5rem;
  border: none;
  background: none;
  color: #ffffff;
  font: 300 0.875rem 'Open Sans', serif;
  font-weight: ${(props) => props.selected && '700'};
  padding: 0 2rem;
  cursor: pointer;
  transition: all 0.25s;
  outline: none;

  ${({ selected }) =>
    selected
      ? css`
          background: #00000011 0% 0% no-repeat padding-box;
        `
      : css`
          :hover {
            background: #0000000a 0% 0% no-repeat padding-box;
          }
        `}
`

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedFilter: 'Novos atendimentos',
    }
  }

  componentDidMount() {
    if (this.props.small) {
      this.setState({
        selectedFilter: 'Novos atendimentos',
      })
    }
  }

  goToNewAppointments = () => {
    this.setState({
      selectedFilter: 'Novos atendimentos',
    })
    this.props.applyFilterTicketsThunk([
      {
        type: 'newStatus',
        selected: ['Aguardando teleconsulta', 'Em atendimento'],
      },
    ])
    this.props.startFirstFetching()
    this.props.saveSelectedTicket()
  }

  goToPlannedAppointments = () => {
    this.setState({
      selectedFilter: 'Retornos',
    })
    this.props.applyFilterTicketsThunk([
      {
        type: 'newStatus',
        selected: ['Retorno com Enfermeiro', 'Encaminhado para Enfermeiro'],
      },
    ])
    this.props.startFirstFetching()
    this.props.saveSelectedTicket()
  }

  goToFinishedAppointments = () => {
    this.setState({
      selectedFilter: 'Finalizado',
    })
    this.props.applyFilterTicketsThunk([
      {
        type: 'newStatus',
        selected: [
          'Finalizado',
          'Encaminhado para pronto socorro',
          'Encaminhado para especialista focal',
        ],
      },
    ])
    this.props.startFirstFetching()
    this.props.saveSelectedTicket()
  }

  handleLogout = () => {
    localStorage.removeItem('waitingPatient')
    this.props.logout()
    this.props.setInitialState()
    this.props.history.replace({ pathname: '/' })
  }

  handleMain = () => {
    this.props.history.replace({ pathname: '/atendimento' })
  }

  render() {
    return (
      <Container background='rgba(0,97,175,1)'>
        <HeaderContent>
          <Logo onClick={this.handleMain} src={logo} />
          {!this.props.hiddenButtons && (
            <Options>
              <Option
                onClick={this.goToNewAppointments}
                selected={this.state.selectedFilter === 'Novos atendimentos'}
              >
                Novos atendimentos
            </Option>
              {this.props.small || (
                <Option
                  border
                  onClick={this.goToPlannedAppointments}
                  selected={this.state.selectedFilter === 'Retornos'}
                >
                  Retornos
                </Option>
              )}
              <Option
                onClick={this.goToFinishedAppointments}
                selected={this.state.selectedFilter === 'Finalizado'}
              >
                Finalizados
            </Option>
            </Options>
          )}
          <LogoutButton onClick={this.handleLogout}>Sair</LogoutButton>
        </HeaderContent>
      </Container>
    )
  }
}

export default connect(null, mapDispatchToProps)(Header)
