// Action Types
export const SAVE_TICKETS = "sirio/dashboard/SAVE_TICKETS";
export const SAVE_SELECTED_TICKET = "sirio/dashboard/SAVE_SELECTED_TICKET";
export const TOGGLE_SELECTED_FILTERS = "sirio/dashboard/TOGGLE_SELECTED_FILTERS";
export const INITIAL_STATE = "sirio/dashboard/INITIAL_STATE";
export const UPDATE_ORDINATION = "sirio/dashboard/UPDATE_ORDINATION";
export const UPDATE_TICKET_INFO = "sirio/dashboard/UPDATE_TICKET_INFO";
export const START_FIRST_FETCHING = "sirio/dashboard/START_FIRST_FETCHING";
export const IS_FETCHING = "sirio/dashboard/IS_FETCHING";
export const IS_FETCHING_SIDEBAR_TAB = "sirio/dashboard/IS_FETCHING_SIDEBAR_TAB";
export const SAVE_NOTES = "sirio/dashboard/SAVE_NOTES";
export const CHANGE_NEW_TEXT_NOTE = 'sirio/dashboard/CHANGE_NEW_TEXT_NOTE';
export const TOGGLE_NOTIFICATION = 'sirio/dashboard/TOGGLE_NOTIFICATION';

export const initialState = {
  tickets: [],
  filters: {
    priority: {
      type: 'priority',
      label: 'Prioridade',
      items: [
        {
          type: 'baixa',
          label: 'Baixa',
        },
        {
          type: 'media',
          label: 'Média'
        },
        {
          type: 'alta',
          label: 'Alta'
        }
      ]
    },
    status: {
      type: 'status',
      label: 'Status',
      items: [
        {
          type: "Aguardando teleconsulta",
          label: "Aguardando teleconsulta"
        },
        {
          type: "Em atendimento",
          label: "Em atendimento"
        },
        {
          type: "Encaminhamento PA",
          label: "Encaminhamento PA"
        },
        {
          type: 'Retorno em 1 dia',
          label: 'Retorno em 1 dia'
        },
        {
          type: 'Retorno em 3 dias',
          label: 'Retorno em 3 dias'
        }
      ]
    },
    client: {
      type: 'client',
      label: 'Cliente',
      items: [
        {
          type: "hsl",
          label: "HSL"
        },
        {
          type: "nubank",
          label: "NuBank"
        },
        {
          type: "itau",
          label: "Itaú"
        },
      ]
    }
  },
  selectedFilters: [{
    type: 'newStatus',
    selected: ['Aguardando teleconsulta', 'Em atendimento']
  }],
  selectedTicket: undefined,
  firstFetching: true,
  isFetching: false,
  orderedBy: {
    type: 'status',
    reverse: false,
  },
  notes: [],
  newNoteText: undefined,
  notification: []
};

// Reducer
export default (state = initialState, action) => {
  let tickets = state.tickets;
  switch (action.type) {
    case INITIAL_STATE:
      return Object.assign({}, state, {
        tickets: [],
        filters: {
          priority: {
            type: 'priority',
            label: 'Prioridade',
            items: [
              {
                type: 'baixa',
                label: 'Baixa',
              },
              {
                type: 'media',
                label: 'Média'
              },
              {
                type: 'alta',
                label: 'Alta'
              }
            ]
          },
          status: {
            type: 'status',
            label: 'Status',
            items: [
              {
                type: "Aguardando teleconsulta",
                label: "Aguardando teleconsulta"
              },
              {
                type: "Em atendimento",
                label: "Em atendimento"
              },
              {
                type: "Encaminhamento PA",
                label: "Encaminhamento PA"
              },
              {
                type: 'Retorno em 1 dia',
                label: 'Retorno em 1 dia'
              },
              {
                type: 'Retorno em 3 dias',
                label: 'Retorno em 3 dias'
              }
            ]
          },
          client: {
            type: 'client',
            label: 'Cliente',
            items: [
              {
                type: "hsl",
                label: "HSL"
              },
              {
                type: "nubank",
                label: "NuBank"
              },
              {
                type: "itau",
                label: "Itaú"
              },
            ]
          }
        },
        selectedFilters: [{
          type: 'newStatus',
          selected: ['Aguardando teleconsulta', 'Em atendimento']
        }],
        selectedTicket: undefined,
        isFetching: false,
        isFetchingSidebarTab: false,
        orderedBy: {
          type: 'status',
          reverse: false,
        },
        notes: [],
        newNoteText: undefined,
        notification: [],
      });
    case IS_FETCHING:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case IS_FETCHING_SIDEBAR_TAB:
      return Object.assign({}, state, {
        isFetchingSidebarTab: true,
      });
    case UPDATE_TICKET_INFO:
      tickets = tickets.map((ticket) => {
        if (ticket.id === action.id) {
          if (action.status) {
            ticket.status = action.status
          }
          if (action.appointmentDate) {
            ticket.ticket.appointmentDate = action.appointmentDate
          }
        }
        return ticket;
      });
      return Object.assign({}, state, {
        tickets
      });
    case SAVE_TICKETS:
      return Object.assign({}, state, {
        tickets: action.info.tickets,
        firstFetching: false,
        isFetching: false,
      });
    case SAVE_SELECTED_TICKET:
      return Object.assign({}, state, {
        selectedTicket: action.ticket,
        isFetchingSidebarTab: false,
      });
    case TOGGLE_SELECTED_FILTERS: {
      return Object.assign({}, state, {
        selectedFilters: action.info,
        notes: [],
        firstFetching: true,
      });
    }
    case UPDATE_ORDINATION:
      return Object.assign({}, state, {
        orderedBy: action.info,
      });
    case START_FIRST_FETCHING:
      return {
        ...state,
        firstFetching: true,
      }
    case SAVE_NOTES:
      return Object.assign({}, state, {
        notes: action.info,
        isFetchingSidebarTab: false,
      });
    case CHANGE_NEW_TEXT_NOTE:
      return Object.assign({}, state, {
        newNoteText: action.info,
      });
    case TOGGLE_NOTIFICATION:
      return Object.assign({}, state, {
        notification: action.info,
      });
    default:
      return state;
  }
};

// Action Creators
export const isFetching = () => {
  return {
    type: IS_FETCHING
  };
};

export const isFetchingSidebarTab = () => {
  return {
    type: IS_FETCHING_SIDEBAR_TAB
  };
};

export const updateTicketInfo = (id, info) => {
  return {
    type: UPDATE_TICKET_INFO,
    id,
    info
  };
};

export const setInitialState = () => {
  return {
    type: INITIAL_STATE
  };
};

export const saveTickets = info => ({
  type: SAVE_TICKETS,
  info
});

export const saveSelectedTicket = ticket => ({
  type: SAVE_SELECTED_TICKET,
  ticket
})

export const toggleSelectedFilters = info => ({
  type: TOGGLE_SELECTED_FILTERS,
  info
});

export const updateOrdination = info => ({
  type: UPDATE_ORDINATION,
  info,
});

export const startFirstFetching = info => ({
  type: START_FIRST_FETCHING,
  info,
});

export const saveNotes = (info) => ({
  type: SAVE_NOTES,
  info,
})

export const changeNewNoteText = (info) => ({
  type: CHANGE_NEW_TEXT_NOTE,
  info,
})

export const toggleNotification = (info) => ({
  type: TOGGLE_NOTIFICATION,
  info,
})