import React, { Suspense } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { persistStore } from 'redux-persist'
import WebFont from 'webfontloader'

// Css
import './index.css'

// store
import store from './dataflow/store'

// pages
import Login from './pages/Login'
import Dashboard from './pages/DashboardNew'
import Private from './components/private'

WebFont.load({
  google: {
    families: ['Open Sans'],
  },
})

persistStore(
  store,
  {
    whitelist: ['auth'],
  },
  () => {
    render(
      <Provider store={store}>
        <>
          <BrowserRouter>
            <Suspense fallback={<div />}>
              <Switch>
                <Route
                  exact
                  path='/'
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path='/atendimento'
                  render={(props) => (
                    <Private {...props}>
                      <Dashboard />
                    </Private>
                  )}
                />
              </Switch>
            </Suspense>
          </BrowserRouter>
        </>
      </Provider>,
      document.getElementById('root')
    )
  }
)
