import React, {Component} from 'react';
import styled from 'styled-components';
import { format } from 'date-fns'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: calc(100% - 28rem);
  height: 100%;
  background: #f6f9fc;
  margin-top: 0.875rem;
`

const StatusItem = styled.label`
  display: flex;
  padding: 0 1rem;
  background-color: ${(props) => props.isSelected && '#00AFEF19'};
  cursor: pointer;
  width: 60%;

  :hover {
    background-color: #00afef19;
    span {
      border-color: transparent;
    }
  }

  :last-child {
    span {
      border: none;
    }
  }
`

const OptionAdma = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
  padding: 1rem 0;
  border-bottom: 0.0625rem solid #00afef19;
`

const Content = styled.div`
  position: relative;
  width: calc(100% - 3rem);
  height: 100%;
  margin: 0 auto;
  border-radius: .25rem;
  background: #fff;
  box-shadow: 0 0 .625rem #00000019;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 4.5rem;
  padding: 0 1.5rem 0 2rem;
  background: #fff;
`;

const ChatHistory = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 9.75rem);
  background: #F6F9FC;
  align-items: center;
  justify-content: center;

  @media (max-width: 667px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const Wrap = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Footer = styled.footer`
  display: flex;
  align-items: center;
  width: 100%;
  height: 5.25rem;
  padding: 0 2rem;
  justify-content: space-around;

  @media (max-width: 667px) {
    height: 4.375rem;
    bottom: -4.375rem;
  }
`;

const ButtonNext = styled.button`
  width: 42%;
  padding: 1rem;
  border: none;
  border-radius: 0.375rem;
  background-color: ${(props) => (props.disabled ? '#0561AF19' : '#0561AF')};
  color: #fff;
  font: 600 0.875rem 'Open Sans', sans-serif;
  letter-spacing: 0.02625rem;
  cursor: ${(props) => !props.disabled && 'pointer'};
  outline: none;
  transition: background 0.1s;
`

const ButtonBack = styled.button`
  width: 42%;
  padding: 1rem;
  border: 1px solid #333333;
  border-radius: 0.375rem;
  background-color: #fff;
  color: #333333;
  font: 600 0.875rem 'Open Sans', sans-serif;
  letter-spacing: 0.02625rem;
  cursor: ${(props) => !props.disabled && 'pointer'};
  outline: none;
  transition: background 0.1s;
`

const TitleAdma = styled.h2`
  max-width: 70%;
  text-align: center;
  margin: 0.5rem 0;
  color: #0061af;
  font: 700 1rem 'Open Sans', sans-serif;
`

const StatusTitle = styled.h2`
  max-width: 70%;
  text-align: center;
  margin: 0.5rem 0;
  color: #0061af;
  font: 700 1.45rem 'Open Sans', sans-serif;
  margin-bottom: 1.5rem;
`

const SubTitle = styled.h3`
  max-width: 70%;
  text-align: center;
  margin: 0.5rem 0;
  font: 700 1rem 'Open Sans', sans-serif;
  margin-bottom: 2rem;
`

const LabelAdma = styled.p`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  user-select: none;
  font: 400 1rem 'Open Sans', sans-serif;
  color: #131516;

  strong {
    font-weight: 600;
    margin-left: 0.25rem;
  }
`

const ReturnSelect = styled.div`
  display: flex;
  margin: 0.75rem auto 0;
  font: 400 0.875rem Open Sans;
  letter-spacing: 0;
  color: #0061af;
`
const Selected = styled.div`
  display: block;
  width: 5rem;
  height: 1.25rem;
  margin-left: 0.25rem;
  padding-left: 0.25rem;
  padding-bottom: 0.25rem;
  font: 600 0.875rem Open Sans;
  color: #0061af;
  border: none;
  border-bottom: 0.03125rem solid #0061af;
  background-color: transparent;
  outline: none;
`
const Options = styled.div`
  position: absolute;
  width: 5rem;
  height: 9.375rem;
  margin-left: 0.25rem;
  padding: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0 0.1875rem 0.375rem #0000000d;
  border-radius: 0 0 0.25rem 0.25rem;
  overflow: hidden scroll;
  outline: none;

  &::-webkit-scrollbar {
    background: transparent;
    width: 0.25rem;
    margin-left: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.625rem;
    background: #94dcf8;
  }

  div {
    font: 300 0.75rem Open Sans;
    padding: 0.25rem;
    letter-spacing: 0;
    color: #4d4c4f;

    :hover {
      background: #00afef10;
    }
  }
`

const Legend = styled.div`
  max-width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LegendText = styled.p`
  max-width: 70%;
  text-align: center;
  margin: 0 1rem;
  color: #0061af;
  font: 600 0.85rem 'Open Sans', sans-serif;
`

const Step = styled.div`
  width: 5rem;
  height: 0.45rem;
  margin: 0.25rem 0.45rem 0;
  background-color: #F6F9FC;
  border-radius: 25px;

  div {
    width: 2.5rem;
    height: 0.45rem;
    background-color: #F6F9FC;
    border-radius: 25px;
  }
`

const EmailTextArea = styled.textarea`
  display: flex;
  width: 80%;
  height: 16.25rem;
  margin: 1rem 0;
  padding: 0.5rem 0.875rem;
  background: #f6f9fc 0% 0% no-repeat padding-box;
  border: 0.03125rem solid #0061af;
  border-radius: 0.1875rem;
  font: 400 0.875rem 'Open Sans', sans-serif;
  resize: initial;
  outline: none;

  ::placeholder {
    color: #0061af66;
    font-style: italic;
    line-height: 1.5rem;
  }

  &::-webkit-scrollbar {
    background: transparent;
    width: 0.25rem;
    margin-left: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.625rem;
    background: #94dcf8;
  }
`

class AfterAppontiment extends Component {
  constructor(props) {
    super(props)

    this.state = {
      step: 'add-status',
      modal: {
        type: '',
        isActive: false,
      },
      selectedStatus: {},
      statusList: [
        {
          type: 'Aguardando teleconsulta',
          label: 'Aguardando teleconsulta',
        },
        {
          type: 'Encaminhamento PA',
          label: 'Encaminhamento PA',
        },
        {
          type: 'Retorno em 1 dia',
          label: 'Retorno em 1 dia',
        },
        {
          type: 'Retorno em 3 dias',
          label: 'Retorno em 3 dias',
        },
        {
          type: 'Finalizado',
          label: 'Finalizado',
        },
      ],
      emailText: '',
      selectedReturn: 1,
      isOpen: false,
    }
  }

  componentDidMount() {
    if (this.props.pacient && this.props.pacient.step) {
      this.setState({
        step: this.props.pacient.step,
      })
    }
  }

  closeModal = () => {
    this.setState({
      selectedStatus: {},
      emailText: '',
      modal: {
        type: '',
        isActive: false,
      },
    })
  }

  handleSelected = (status) => {
    this.setState({
      selectedStatus: status,
      isOpen: false,
    })
  }

  handleOpenSelect = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }))
  }


  handleEmailNoteText = (ev) => {
    this.setState({
      emailText: ev.target.value.trim(),
    })
  }

  updateStatus = () => {
    const { selectedStatus } = this.state
    const { pacient } = this.props

    const status = {
      status: selectedStatus.type,
    }

    if (
      selectedStatus.type === 'Encaminhado para Enfermeiro' ||
      selectedStatus.type === 'Retorno com Enfermeiro'
    ) {
      let date = new Date()
      date.setDate(date.getDate() + this.state.selectedReturn)

      date = date.toISOString()
      date = `${date.substring(0, date.lastIndexOf('.'))}-03:00`

      status.appointmentDate = date
    }

    if (selectedStatus.type) {
      if (selectedStatus.type === 'finalizado') {
        this.props.closeTicketThunk(pacient.id)
      } else {
        this.props.updateTicketThunk(pacient, status)
      }
    }
  }

  saveReturnDate = (date) => {
    this.setState({
      selectedReturn: date,
    })
  }

  handleOpenSelect = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }))
  }

  renderListAdma = () => {
    const { selectedStatus, userType } = this.state
    const physicianStatusListAdma = [
      {
        type: 'Retorno para Médico de Família',
        label: ' Médico de Família',
        complement: 'Retorno para ',
      },
      {
        type: 'Encaminhado para Enfermeiro',
        label: ' Enfermeiro',
        complement: 'Encaminhado para ',
      },
      {
        type: 'Encaminhado para especialista focal',
        label: ' Especialista Focal',
        complement: ' Encaminhado para ',
      },
      {
        type: 'Encaminhado para pronto socorro',
        label: ' Pronto Atendimento',
        complement: ' Encaminhado para ',
      },
      {
        type: 'finalizado',
        complement: ' Finalizado',
      },
    ]

    const nurseStatusListAdma = [
      {
        type: 'Retorno com Enfermeiro',
        label: ' Enfermeiro',
        complement: 'Retorno com ',
      },
      {
        type: 'Encaminhado para Médico de Família',
        label: ' Médico de Família',
        complement: 'Encaminhado para ',
      },
      {
        type: 'Encaminhado para especialista focal',
        label: ' Especialista Focal',
        complement: ' Encaminhado para ',
      },
      {
        type: 'Encaminhado para pronto socorro',
        label: ' Emergência',
        complement: ' Encaminhado para ',
      },
      {
        type: 'finalizado',
        complement: ' Finalizado',
      },
    ]
    const list =
      userType === 'physician' ? physicianStatusListAdma : nurseStatusListAdma

    return list.map((item) => {
      const isSelected = item.type === selectedStatus.type
      const { isOpen } = this.state

      const handleSelectedStatus = (ev) => {
        ev.stopPropagation()
        this.handleSelected(item)
      }

      const showOptionsList = () => {
        return options.map((day) => {
          const selectReturnOption = (ev) => {
            ev.stopPropagation()
            this.setState({
              selectedReturn: day,
            })
            this.saveReturnDate(day)
            this.handleOpenSelect()
          }

          return (
            <div onClick={selectReturnOption} key={day}>
              {day} dia{day > 1 ? 's' : ''}
            </div>
          )
        })
      }

      const options = []
      for (let i = 1; i < 31; i++) {
        options.push(i)
      }

      return (
        <StatusItem
          key={item.type}
          onClick={handleSelectedStatus}
          marginBottom='2rem'
          isSelected={isSelected}
        >
          <OptionAdma>
            <LabelAdma>
              {item.complement}
              {item.label && <strong>{`${item.label}`}</strong>}
            </LabelAdma>
            {isSelected &&
              (item.type === 'Retorno com Enfermeiro' ||
                item.type === 'Encaminhado para Enfermeiro') && (
                <ReturnSelect>
                  retornar em
                  <div>
                    <Selected
                      onClick={(ev) => {
                        ev.stopPropagation()
                        this.handleOpenSelect()
                      }}
                    >
                      {this.state.selectedReturn
                        ? `${this.state.selectedReturn} dia${this.state.selectedReturn > 1 ? 's' : ''
                        }`
                        : ''}
                    </Selected>
                    {isOpen && <Options>{showOptionsList()}</Options>}
                  </div>
                </ReturnSelect>
              )}
          </OptionAdma>
        </StatusItem>
      )
    })
  }

  treatData = (data) => {
    if (data && data.length > 0) {
      return data.split('";"').map((i) => {
        const items = i.split(':').map((a) => a.replace('"', ''))
        const key = items[0].replace('"', '')
        const valors = items
          .filter((a) => a.replace('"', '') !== key)
          .map((a) => a.split(';').map((b) => b.replace('"', '')))
          .flat(Infinity)
        return { key, valors }
      })
    }
  }

  sendEmail = () => {
    const { emailText, selectedStatus } = this.state
    const { pacient, doctorName } = this.props

    const date = pacient.date.split('/')
    const trial = this.treatData(
      this.props.pacient.ticket.trial
    )
    const reason = trial && trial[0].valors[0]
    const data = {
      username: doctorName,
      patientName: pacient.ticket.name,
      patientEmail: pacient.ticket.email,
      startDate: `${format(
        new Date(date[2], date[1] - 1, date[0]),
        'yyyy/MM/dd'
      )} ${format(new Date(), 'HH:mm:ss')}`,
      status: selectedStatus.type,
      recomendations: emailText.length > 0 ? emailText : ' ',
      reason,
    }
    this.props.satisfactionSurveyThunk(pacient.ticket.cpf)
    this.props.endCallSendEmailThunk(data)
  }

  handleNext = () => {
    let newStep = '';

    if (this.state.step === 'add-status') {
      if (this.state.selectedStatus.type) {
        this.updateStatus()
        newStep = 'recommendations-first'
      }
    }
    if (this.state.step === 'recommendations-first') {
      newStep = 'recommendations-second'
    }

    this.setState({
      step: newStep
    })
  }

  finishAfterAll = () => {
    this.sendEmail()
    this.props.leaveAfterAppointment()
  }

  render () {
    const { userType } = this.props
    const { step } = this.state

    return (
      <Container>
          <Content>
            <Header>
              <TitleAdma>Finalizando Atendimento</TitleAdma>
              <Legend>
                <LegendText>Status do paciente</LegendText>
                <Step style={{ background: '#0061AF' }}></Step>
                <Step style={step === 'recommendations-second' ? { background: '#0061AF' } : null}>
                  <div style={step === 'recommendations-first' || step === 'recommendations-second' ? { background: '#0061AF' } : null}></div>
                </Step>
              </Legend>
            </Header>
              {step === 'add-status' && (
                <>
                  <ChatHistory>
                    <StatusTitle>Qual o novo status do paciente?</StatusTitle>
                    {this.renderListAdma(userType)}
                  </ChatHistory>
                  <Footer>
                    <ButtonBack onClick={this.props.backToRoom}>
                      Voltar para o chat
                    </ButtonBack>
                    <ButtonNext
                      disabled={!this.state.selectedStatus.type}
                      onClick={this.handleNext}
                    >
                      Avançar
                    </ButtonNext>
                  </Footer>
                </>
              )}
              {step === 'recommendations-first' && (
                <>
                  <ChatHistory>
                    <Wrap>
                      <SubTitle>O paciente foi liberado.</SubTitle>
                      <p style={{ font: '400 1rem Open Sans' }}>Se necessário, você pode adicionar observações e recomendações ao e-mail que o paciente recebe após o atendimento. </p>
                      <small style={{ font: '400 0.75rem Open Sans', margin: '1.25rem 0 0 0' }}>Obs.: O atendimento pode ser finalizado sem adição de recomendações.</small>
                      <StatusTitle style={{ margin: '2rem 0 0 0' }}>Deseja adicionar recomendações?</StatusTitle>
                    </Wrap>
                  </ChatHistory>
                  <Footer>
                    <ButtonBack onClick={this.finishAfterAll}>
                      Finalizar atendimento
                    </ButtonBack>
                    <ButtonNext onClick={this.handleNext}>
                      Adicionar recomendações
                    </ButtonNext>
                  </Footer>
                </>
              )}
            {step === 'recommendations-second' && (
              <>
                <ChatHistory>
                  <Wrap>
                    <SubTitle>Insira as recomendações no campo abaixo.</SubTitle>
                    <EmailTextArea
                      onChange={this.handleEmailNoteText}
                      placeholder='Digite aqui o email com orientações ao paciente...'
                    />
                  </Wrap>
                </ChatHistory>
                <Footer>
                  <ButtonNext
                    style={{ background: '#333333'}}
                    onClick={this.finishAfterAll}
                  >
                    Finalizar atendimento
                  </ButtonNext>
                </Footer>
              </>
            )}
          </Content>
      </Container>
    )
  }
}

export default AfterAppontiment;