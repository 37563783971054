import React from "react";
import {
  PeerList,
  LocalMediaList,
  UserControls
} from "@andyet/simplewebrtc";
import styled, { css } from "styled-components";
import Draggable from 'react-draggable';

// Components
import Me from "./Me";
import Peer from "./Peer";

// Images
import micOn from '../../assets/mic.svg';
import micOff from '../../assets/mic_off.svg';
import micOffHover from '../../assets/mic_off_hover.svg';
import videoOn from '../../assets/video_on.svg';
import videoOff from '../../assets/video_off.svg';
import videoOffHover from '../../assets/video_off_hover.svg';

import endCall from '../../assets/call_end.svg';


const VideoContainer = styled.div`
  position: fixed;
  top: 10%;
  left: 30%;
  width: 50%;
  max-width: 60rem;
  height: 40vw;
  min-height: 31.25rem;
  max-height: 41.875rem;
  margin: 0 auto;
  border-radius: .5625rem;
  background: #fff;
  box-shadow: 0 0 .625rem #00000019;
  overflow: hidden;
  z-index: 3;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4.5rem;
  padding: 0 1.5rem 0 2.5rem;
  background: #fff;
  cursor: move;
`;

const ContentVideo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 4.5rem);
  /* background: greenyellow; */
  overflow: hidden;
`;

const VideoCallbutton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  background-color: none;
  outline: none;
  font: 400 0.5rem 'Open Sans', sans-serif;
  background: none;
  cursor: pointer;
  margin: 0 1rem;

  ${({ hover }) => hover && css`
    :hover {
      img {
        content: url(${props => props.hoverIcon})
      }
    }
  `}
`;

const ButtonIcon = styled.img`
  width: 1.5625rem;
`;

const TesteContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`;

export default class VideoCall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      video: true,
      mic: true,
      activeDrags: 0,
      deltaPosition: {
        x: 0, y: 0
      },
      controlledPosition: {
        x: -400, y: 200
      }
    }
  }

  onStart = () => {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.setState({ activeDrags: ++this.state.activeDrags });
  };

  onStop = () => {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.setState({ activeDrags: --this.state.activeDrags });
  };


  render() {
    const { roomAddress, toggleVideo } = this.props;
    const { video, mic } = this.state;
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };

    return (
      <TesteContainer>
        <Draggable handle="#draggable-dialog-title" bounds="parent" {...dragHandlers}>
          {/* <div className="box no-cursor">
            <strong className="cursor"><div>Drag here</div></strong>
            <div>You must click my handle to drag me</div>
          </div> */}
          <VideoContainer>
            <Header id="draggable-dialog-title">
              <UserControls
                render={({ pauseVideo, resumeVideo }) => (
                  <VideoCallbutton
                    hover={!video}
                    hoverIcon={videoOffHover}
                    onClick={() => {
                      this.setState({ video: !this.state.video })
                      video ? pauseVideo() : resumeVideo();
                    }}
                  >
                    <ButtonIcon src={video ? videoOn : videoOff} />
                    {video ? 'Desligar' : 'Ligar'} câmera
                  </VideoCallbutton>
                )}
              />
              <UserControls
                render={({ mute, unmute }) => (
                  <VideoCallbutton
                    hover={!mic}
                    hoverIcon={micOffHover}
                    onClick={() => {
                      this.setState({ mic: !this.state.mic })
                      mic ? mute() : unmute();
                    }}
                  >
                    <ButtonIcon src={mic ? micOn : micOff} />
                    {mic ? 'Desligar' : 'Ligar'} microfone
                  </VideoCallbutton>
                )}
              />
              <UserControls
                render={({ pauseVideo, mute }) => (
                  <VideoCallbutton
                    onClick={() => {
                      mute();
                      pauseVideo();
                      toggleVideo();
                    }}
                  >
                    <ButtonIcon src={endCall} />
                    Finalizar chamada
                  </VideoCallbutton>
                )}
              />
            </Header>
            <ContentVideo>
              <PeerList
                room={roomAddress}
                render={({ peers }) => <Peer peers={peers} />}
              />
              <LocalMediaList
                audio={true}
                video={true}
                render={({ media }) => <Me media={media} toggleVideo={toggleVideo} />}
              />
            </ContentVideo>
          </VideoContainer>
        </Draggable>
      </TesteContainer>
    );
  }
};
