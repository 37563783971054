// Libs
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.25rem);
  z-index: 10;
`

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 28.125rem;
  padding: 2.25rem;
  border-radius: 0.375rem;
  background-color: #fff;
  box-shadow: 0 0 0.625rem #00000029;
`

const Text = styled.p`
  margin: 0.5rem 0;
  margin-bottom: 1rem;
  color: #0561af;
  font: 600 1rem 'Open Sans', sans-serif;
`

const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`

const CancellButton = styled.button`
  width: 6.25rem;
  height: 1.625rem;
  margin: 0 0.625rem;
  padding: 0 0.5rem;
  border: none;
  border-radius: 0.5625rem;
  background-color: #0561af;
  color: #fff;
  font: 700 0.85rem 'Open Sans', sans-serif;
  cursor: pointer;
  outline: none;
`

const EndCall = (props) => (
  <Container onClick={props.closeModal}>
    <Modal onClick={(ev) => ev.stopPropagation()}>
      <Text>Tem certeza que deseja terminar a consulta?</Text>
      <ContainerButtons>
        <CancellButton onClick={props.closeModal}>Não</CancellButton>
        <CancellButton onClick={props.closeTicket}>Sim</CancellButton>
      </ContainerButtons>
    </Modal>
  </Container>
)

export default EndCall
