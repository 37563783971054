import React from 'react'
import { RemoteMediaList, Video } from '@andyet/simplewebrtc'
import styled from 'styled-components'

// Images
import videoCamOff from '../../assets/videocam_off-24px.svg'

// Styled
const VideoContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #e3e3e3;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 667px) {
    justify-content: center;
    max-width: 100%;

    video {
      min-width: max-content;
    }
  }
`

const NoVideo = styled.div`
  display: flex;
  background-color: #e3e3e3;
  width: 100%;
  height: 100%;
`

const Icon = styled.img`
  margin: auto;
  width: 20%;
`

const PeerVideo = ({ media }) => {
  const videoStreams = media.filter(
    (m) => m.kind === 'video' && !m.remoteDisabled
  )
  // const audioStreams = media.filter(m => m.kind === "audio");

  if (videoStreams.length > 0) {
    // Choose last media as it is most likely the screenshare.
    const webcamStreams = videoStreams.filter((s) => !s.screenCapture)
    // const screenCaptureStreams = videoStreams.filter(s => s.screenCapture);
    return <Video media={webcamStreams[0]} />
  }
  return (
    <NoVideo>
      <Icon src={videoCamOff} />
    </NoVideo>
  )
  // return <Icon src={videoCamOff}/>;
}

export default ({ peers }) =>
  peers.map((item, key) => (
    <RemoteMediaList
      key={key}
      audio
      video
      peer={item.address}
      render={({ media }) => (
        <VideoContainer>
          <PeerVideo media={media} />
        </VideoContainer>
      )}
    />
  ))
