import React from 'react'
import { Video } from '@andyet/simplewebrtc'
import styled from 'styled-components'

const VideoContainer = styled.div`
  position: absolute;
  right: 1vw;
  bottom: 1vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 13vw;
  height: 10vw;
  overflow: hidden;

  @media (max-width: 667px) {
    bottom: 18vh;
    right: 7vw;
    width: 32vw;
    height: 24vh;

    video {
      min-width: max-content;
    }
  }
`

export default ({ media }) => {
  // const audioStreams = media.filter(m => m.kind === "audio");
  const videoStreams = media.filter((m) => m.kind === 'video')
  // const latestAudio = audioStreams[audioStreams.length - 1];
  const latestVideo = videoStreams[videoStreams.length - 1]

  return (
    <VideoContainer>
      <Video media={latestVideo} />
    </VideoContainer>
  )
}
