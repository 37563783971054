// Libs
import React, { Component } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25rem;
  padding: 2.25rem;
  border-radius: 0.75rem;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem #00000080;
`

const Title = styled.p`
  margin: 0.5rem 0;
  margin-bottom: 1.5rem;
  color: #0561af;
  font: 400 1rem 'Open Sans', sans-serif;
`

const Status = styled.strong`
  margin-left: 0.25rem;
`

const CloseButton = styled.button`
  width: 6.25rem;
  height: 1.625rem;
  margin: 0 0.652rem;
  padding: 0 0.5rem;
  border: none;
  border-radius: 0.5625rem;
  background-color: #0561af;
  color: #fff;
  font: 700 0.85rem 'Open Sans', sans-serif;
  cursor: pointer;
  outline: none;
`

class FinishConfirmation extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <Container onClick={this.props.closeModal}>
        <InnerContainer onClick={(ev) => ev.stopPropagation()}>
          <Title>
            Status alterado para:
            <Status>
              {this.props.selectedStatus.complement &&
                `${this.props.selectedStatus.complement} `}
              {this.props.selectedStatus.label}
            </Status>
          </Title>
          <CloseButton onClick={this.props.closeModal}>Ok</CloseButton>
        </InnerContainer>
      </Container>
    )
  }
}

export default FinishConfirmation
