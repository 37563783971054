/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-plusplus */
// Libs
import React, { Component } from 'react'
import styled from 'styled-components'

import close from '../assets/close.svg'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.25rem);
  z-index: 10;
`

const InnerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 27.5rem;
  padding: 1.75rem 0 1.5rem;
  border-radius: 0.375rem;
  background-color: #fff;
  box-shadow: 0 0 0.625rem #00000029;
`
const ContainerList = styled.div`
  width: 100%;
`

const StatusItem = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 1.25rem auto;
`

const Label = styled.span`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font: 300 0.9rem 'Open Sans', sans-serif;

  &:before {
    content: '';
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.75rem;
    border: ${(props) =>
      props.isSelected ? '.0625rem solid transparent' : '.0625rem solid black'};
    border-radius: 0.3125rem;
    background-color: ${(props) => (props.isSelected ? '#0561AF' : '#FFF')};
  }
`

const Button = styled.button`
  width: 80%;
  margin-top: 1.5rem;
  padding: 1rem;
  border: none;
  border-radius: 0.375rem;
  background-color: ${(props) => (props.disabled ? '#0561AF19' : '#0561AF')};
  color: #fff;
  font: 600 0.875rem 'Open Sans', sans-serif;
  letter-spacing: 0.02625rem;
  cursor: ${(props) => !props.disabled && 'pointer'};
  outline: none;
  transition: background 0.1s;
`

const EmailTextArea = styled.textarea`
  display: flex;
  width: 80%;
  height: 16.25rem;
  margin: 1rem 0;
  padding: 0.5rem 0.875rem;
  background: #f6f9fc 0% 0% no-repeat padding-box;
  border: 0.03125rem solid #0061af;
  border-radius: 0.1875rem;
  font: 400 0.875rem 'Open Sans', sans-serif;
  resize: initial;
  outline: none;

  ::placeholder {
    color: #0061af66;
    font-style: italic;
    line-height: 1.5rem;
  }

  &::-webkit-scrollbar {
    background: transparent;
    width: 0.25rem;
    margin-left: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.625rem;
    background: #94dcf8;
  }
`

const Close = styled.img`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  width: 0.75rem;
  cursor: pointer;
`

const LabelAdma = styled.p`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  user-select: none;
  font: 400 1rem 'Open Sans', sans-serif;
  color: #131516;

  strong {
    font-weight: 600;
    margin-left: 0.25rem;
  }
`

const TitleAdma = styled.p`
  max-width: 70%;
  text-align: center;
  margin: 0.5rem 0;
  color: #0061af;
  font: 400 1.25rem 'Open Sans', sans-serif;
`

const StatusItemAdma = styled.label`
  display: flex;
  width: 100%;
  padding: 0 1rem;
  background-color: ${(props) => props.isSelected && '#00AFEF19'};
  cursor: pointer;

  :hover {
    background-color: #00afef19;
    span {
      border-color: transparent;
    }
  }

  :last-child {
    span {
      border: none;
    }
  }
`

const OptionAdma = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
  padding: 1rem 0;
  border-bottom: 0.0625rem solid #00afef19;
`

const ReturnSelect = styled.div`
  display: flex;
  margin: 0.75rem auto 0;
  font: 400 0.875rem Open Sans;
  letter-spacing: 0;
  color: #0061af;
`

const Selected = styled.div`
  display: block;
  width: 5rem;
  height: 1.25rem;
  margin-left: 0.25rem;
  padding-left: 0.25rem;
  padding-bottom: 0.25rem;
  font: 600 0.875rem Open Sans;
  color: #0061af;
  border: none;
  border-bottom: 0.03125rem solid #0061af;
  background-color: transparent;
  outline: none;
`

const Options = styled.div`
  position: absolute;
  width: 5rem;
  height: 9.375rem;
  margin-left: 0.25rem;
  padding: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0 0.1875rem 0.375rem #0000000d;
  border-radius: 0 0 0.25rem 0.25rem;
  overflow: hidden scroll;
  outline: none;

  &::-webkit-scrollbar {
    background: transparent;
    width: 0.25rem;
    margin-left: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.625rem;
    background: #94dcf8;
  }

  div {
    font: 300 0.75rem Open Sans;
    padding: 0.25rem;
    letter-spacing: 0;
    color: #4d4c4f;

    :hover {
      background: #00afef10;
    }
  }
`

class PacientStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedReturn: 1,
      step: 'select',
    }
  }

  renderList = () => {
    const { statusList, selectedStatus } = this.props

    return statusList.map((item) => {
      const isSelected = item.type === selectedStatus.type

      const handleSelectedStatus = () => {
        this.props.handleSelectedStatus(item)
      }

      return (
        <StatusItem key={item.type} onClick={handleSelectedStatus}>
          <Label isSelected={isSelected}>{item.label}</Label>
        </StatusItem>
      )
    })
  }

  renderListAdma = () => {
    const { selectedStatus, userType } = this.props
    const physicianStatusListAdma = [
      {
        type: 'Retorno para Médico de Família',
        label: ' Médico de Família',
        complement: 'Retorno para ',
      },
      {
        type: 'Encaminhado para Enfermeiro',
        label: ' Enfermeiro',
        complement: 'Encaminhado para ',
      },
      {
        type: 'Encaminhado para especialista focal',
        label: ' Especialista Focal',
        complement: ' Encaminhado para ',
      },
      {
        type: 'Encaminhado para pronto socorro',
        label: ' Pronto Atendimento',
        complement: ' Encaminhado para ',
      },
      {
        type: 'finalizado',
        complement: ' Finalizado',
      },
    ]

    const nurseStatusListAdma = [
      {
        type: 'Retorno com Enfermeiro',
        label: ' Enfermeiro',
        complement: 'Retorno com ',
      },
      {
        type: 'Encaminhado para Médico de Família',
        label: ' Médico de Família',
        complement: 'Encaminhado para ',
      },
      {
        type: 'Encaminhado para especialista focal',
        label: ' Especialista Focal',
        complement: ' Encaminhado para ',
      },
      {
        type: 'Encaminhado para pronto socorro',
        label: ' Emergência',
        complement: ' Encaminhado para ',
      },
      {
        type: 'finalizado',
        complement: ' Finalizado',
      },
    ]

    const list =
      userType === 'physician' ? physicianStatusListAdma : nurseStatusListAdma

    return list.map((item) => {
      const isSelected = item.type === selectedStatus.type
      const { isOpen } = this.props

      const handleSelectedStatus = (ev) => {
        ev.stopPropagation()
        this.props.handleSelectedStatus(item)
      }

      const showOptionsList = () => {
        return options.map((day) => {
          const selectReturnOption = (ev) => {
            ev.stopPropagation()
            this.setState({
              selectedReturn: day,
            })
            this.props.saveReturnDate(day)
            this.props.handleOpenSelect()
          }

          return (
            <div onClick={selectReturnOption} key={day}>
              {day} dia{day > 1 ? 's' : ''}
            </div>
          )
        })
      }

      const options = []
      for (let i = 1; i < 31; i++) {
        options.push(i)
      }

      return (
        <StatusItemAdma
          key={item.type}
          onClick={handleSelectedStatus}
          marginBottom='2rem'
          isSelected={isSelected}
        >
          <OptionAdma>
            <LabelAdma>
              {item.complement}
              {item.label && <strong>{`${item.label}`}</strong>}
            </LabelAdma>
            {isSelected &&
              (item.type === 'Retorno com Enfermeiro' ||
                item.type === 'Encaminhado para Enfermeiro') && (
                <ReturnSelect>
                  retornar em
                  <div>
                    <Selected
                      onClick={(ev) => {
                        ev.stopPropagation()
                        this.props.handleOpenSelect()
                      }}
                    >
                      {this.state.selectedReturn
                        ? `${this.state.selectedReturn} dia${
                            this.state.selectedReturn > 1 ? 's' : ''
                          }`
                        : ''}
                    </Selected>
                    {isOpen && <Options>{showOptionsList()}</Options>}
                  </div>
                </ReturnSelect>
              )}
          </OptionAdma>
        </StatusItemAdma>
      )
    })
  }

  saveSelectedStatus = (ev) => {
    this.props.updateStatus(ev)
  }

  renderEmailNotes = () => {
    const { emailText } = this.props
    return (
      <>
        <TitleAdma>
          Agora é só enviar um e-mail de observações ao paciente
        </TitleAdma>
        <EmailTextArea
          onChange={this.props.handleEmailNoteText}
          placeholder='Digite aqui o email com orientações ao paciente...'
        />
        <Button onClick={this.saveSelectedStatus}>
          {emailText.length > 0 ? 'Enviar e e' : 'E'}ncerrar atendimento
        </Button>
      </>
    )
  }

  renderSelectList = () => {
    const { selectedStatus, userType, noEmail } = this.props
    return (
      <>
        <TitleAdma>Qual o novo status do paciente?</TitleAdma>
        <ContainerList>{this.renderListAdma(userType)}</ContainerList>
        <Button
          disabled={selectedStatus && !selectedStatus.type}
          onClick={(ev) =>
            noEmail
              ? this.saveSelectedStatus(ev)
              : this.setState({ step: 'notes' })
          }
        >
          {noEmail ? 'Trocar status' : 'Avançar'}
        </Button>
      </>
    )
  }

  render() {
    return (
      <Container onClick={this.props.closeModal}>
        <InnerContainer
          adma={this.props.pacient.ticket.type === 'general-practice'}
          onClick={(ev) => ev.stopPropagation()}
        >
          <Close src={close} onClick={this.props.closeModal} />
          {this.state.step === 'notes'
            ? this.renderEmailNotes()
            : this.renderSelectList()}
        </InnerContainer>
      </Container>
    )
  }
}

export default PacientStatus
