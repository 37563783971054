export default [
  {
    id: 1,
    risk: 'alta',
    name: 'João da Silva',
    date: '01/01/1970',
    status: 'Aguardando Teleconsulta',
  },
  {
    id: 2,
    risk: 'medium',
    name: 'Camila Ribeiro',
    date: '01/01/1976',
    status: 'Encaminhado PA, retorno 1 dia',
  },
  {
    id: 3,
    risk: 'low',
    name: 'José Pereira',
    date: '01/01/1976',
    status: 'Retorno 7 dias',
  },
  {
    id: 4,
    risk: 'alta',
    name: 'João da Silva',
    date: '01/01/1970',
    status: 'Aguardando Teleconsulta',
  },
  {
    id: 5,
    risk: 'medium',
    name: 'Camila Ribeiro',
    date: '01/01/1976',
    status: 'Encaminhado PA, retorno 1 dia',
  },
  {
    id: 6,
    risk: 'low',
    name: 'José Pereira',
    date: '01/01/1976',
    status: 'Retorno 7 dias',
  },
  {
    id: 7,
    risk: 'alta',
    name: 'João da Silva',
    date: '01/01/1970',
    status: 'Aguardando Teleconsulta',
  },
  {
    id: 8,
    risk: 'medium',
    name: 'Camila Ribeiro',
    date: '01/01/1976',
    status: 'Encaminhado PA, retorno 1 dia',
  },
  {
    id: 9,
    risk: 'low',
    name: 'José Pereira',
    date: '01/01/1976',
    status: 'Retorno 7 dias',
  },
  {
    id: 10,
    risk: 'alta',
    name: 'João da Silva',
    date: '01/01/1970',
    status: 'Aguardando Teleconsulta',
  },
  {
    id: 11,
    risk: 'medium',
    name: 'Camila Ribeiro',
    date: '01/01/1976',
    status: 'Encaminhado PA, retorno 1 dia',
  },
  {
    id: 12,
    risk: 'low',
    name: 'José Pereira',
    date: '01/01/1976',
    status: 'Retorno 7 dias',
  },
]