// Libs
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { format } from 'date-fns'

// Components
import EndCall from './end-call'
import PacientStatus from './pacient-status'
import EmailSended from './email_sended'

// Images
import close from '../assets/close-white.svg'

// Redux
import {
  updateTicketThunk,
  closeTicketThunk,
  endCallThunk,
  endCallSendEmailThunk,
} from '../dataflow/thunks/dashboard-thunks'

const mapStateToProps = (state) => ({
  selectedTicket: state.dashboard.selectedTicket,
  userType: state.auth.userdata.profile,
})

const mapDispatchToProps = (dispatch) => ({
  updateTicketThunk: (ticket, status) =>
    dispatch(updateTicketThunk(ticket, status)),
  closeTicketThunk: (selectedTicket) => {
    dispatch(closeTicketThunk(selectedTicket))
  },
  endCallThunk: (roomId) => endCallThunk(roomId),
  endCallSendEmailThunk: (doctorName, pacient, status, emailNote) =>
    endCallSendEmailThunk(doctorName, pacient, status, emailNote),
})

const Container = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  z-index: 3;
`

const Button = styled.button`
  position: relative;
  width: 100%;
  padding: 1rem;
  background: #333333 0% 0% no-repeat padding-box;
  box-shadow: 0 0.1875rem 0.375rem #00000029;
  border-radius: 0 0 0.1875rem 0.1875rem;
  border: none;
  color: #ffffff;
  font: 400 0.95rem 'Open Sans', sans-serif;
  cursor: pointer;
  outline: none;
`

const Close = styled.img`
  position: absolute;
  top: 1.25rem;
  right: 1.875rem;
  width: 0.875rem;
`

class CallHeader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: {
        type: '',
        isActive: false,
      },
      selectedStatus: {},
      statusList: [
        {
          type: 'Aguardando teleconsulta',
          label: 'Aguardando teleconsulta',
        },
        {
          type: 'Encaminhamento PA',
          label: 'Encaminhamento PA',
        },
        {
          type: 'Retorno em 1 dia',
          label: 'Retorno em 1 dia',
        },
        {
          type: 'Retorno em 3 dias',
          label: 'Retorno em 3 dias',
        },
        {
          type: 'Finalizado',
          label: 'Finalizado',
        },
      ],
      emailText: '',
      selectedReturn: 1,
      isOpen: false,
    }
  }

  handleSelectedStatus = (status) => {
    this.setState({
      selectedStatus: status,
      isOpen: false,
    })
  }

  closeModal = () => {
    this.setState({
      selectedStatus: {},
      emailText: '',
      modal: {
        type: '',
        isActive: false,
      },
    })
  }

  openModal = (type) => {
    this.setState({
      modal: {
        type,
        isActive: true,
      },
    })
  }

  updateStatus = () => {
    const { roomId, doctorName, selectedTicket } = this.props

    const status = {
      status: this.state.selectedStatus.type,
    }

    if (
      this.state.selectedStatus.type === 'Encaminhado para Enfermeiro' ||
      this.state.selectedStatus.type === 'Retorno com Enfermeiro'
    ) {
      let date = new Date()
      date.setDate(date.getDate() + this.state.selectedReturn)

      date = date.toISOString()
      date = `${date.substring(0, date.lastIndexOf('.'))}-03:00`

      status.appointmentDate = date
    }

    if (this.state.selectedStatus.type) {
      if (this.state.selectedStatus.type === 'finalizado') {
        this.props.closeTicketThunk(roomId)
      } else {
        this.props.updateTicketThunk(this.props.selectedTicket, status)
      }

      if (this.state.emailText.length > 0) {
        const date = selectedTicket.date.split('/')
        const trial = this.props.treatData(
          this.props.selectedTicket.ticket.trial
        )
        const reason = trial && trial[0].valors[0]
        const data = {
          username: doctorName,
          patientName: selectedTicket.ticket.name,
          patientEmail: selectedTicket.ticket.email,
          startDate: `${format(
            new Date(date[2], date[1] - 1, date[0]),
            'yyyy/MM/dd'
          )} ${format(new Date(), 'HH:mm:ss')}`,
          status: this.state.selectedStatus.type,
          recomendations: this.state.emailText,
          reason,
        }

        this.openModal('finishConfirmation')
        this.props.endCallSendEmailThunk(data)
      } else {
        this.redirectToLastScreen()
      }
    }
  }

  handleEmailNoteText = (ev) => {
    this.setState({
      emailText: ev.target.value.trim(),
    })
  }

  saveReturnDate = (date) => {
    this.setState({
      selectedReturn: date,
    })
  }

  handleOpenSelect = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }))
  }

  redirectToLastScreen = () => {
    const { roomId } = this.props
    this.props.endCallThunk(roomId)
    this.props.history.replace({
      pathname: '/atendimento',
    })
    this.props.handleCloseChat()
  }

  renderEndCall = () => {
    const { modal } = this.state

    if (modal.isActive && modal.type === 'endCall') {
      return (
        <EndCall
          closeTicket={this.props.leaveRoom}
          closeModal={this.closeModal}
          pacient={this.props.pacient}
        />
      )
    }

    if (modal.isActive && modal.type === 'pacientStatus') {
      return (
        <PacientStatus
          handleSelectedStatus={this.handleSelectedStatus}
          selectedStatus={this.state.selectedStatus}
          statusList={this.state.statusList}
          closeModal={this.closeModal}
          pacient={this.props.pacient}
          updateStatus={this.updateStatus}
          saveReturnDate={this.saveReturnDate}
          handleEmailNoteText={this.handleEmailNoteText}
          emailText={this.state.emailText}
          isOpen={this.state.isOpen}
          selectedReturn={this.state.selectedReturn}
          userType={this.props.userType}
          handleOpenSelect={this.handleOpenSelect}
        />
      )
    }

    if (modal.isActive && modal.type === 'finishConfirmation') {
      return (
        <EmailSended
          closeModal={this.redirectToLastScreen}
          pacient={this.props.pacient}
        />
      )
    }

    return null
  }

  render() {
    return (
      <Container>
        <Button
          onClick={() => {
            this.openModal('endCall')
          }}
        >
          Finalizar teleconsulta
          <Close src={close} />
        </Button>
        {this.renderEndCall()}
      </Container>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CallHeader)
