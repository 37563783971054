// Libs
import axios from "axios";
import { format } from "date-fns";

import {
  saveTickets,
  toggleNotification,
  toggleSelectedFilters,
  isFetching,
  isFetchingSidebarTab,
  updateTicketInfo,
  saveSelectedTicket,
  saveNotes,
} from "../modules/dashboard-module";

let connection = axios.create({
  baseURL: process.env.REACT_APP_SEARCH_API,
  timeout: 35000,
  headers: {
    "Content-Type": "application/json"
  }
});

const BASE_URL =`${process.env.REACT_APP_SEARCH_API}/atendimentos/search`;

const getTickets = (filter, token) =>
  axios({
    method: "post",
    url: BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: filter
  });

export const getNotesByPatient = (cpf) => async (dispatch, getState) => {
  try {
    const { token } = getState().auth;
    dispatch(isFetchingSidebarTab());
    const response = await connection({
      method: "get",
      url: `/notas?cpf=${cpf}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
    dispatch(
      saveNotes(response.data)
    );
  }
  catch (err) {
    console.log("err", err)
  }
}

export const removeNote = (noteId) => async (dispatch, getState) => {
  try {
    const { token } = getState().auth;
    const { notes } = getState().dashboard;

    const response = await connection({
      method: "delete",
      url: `/notas?id=${noteId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })

    const newNotes = notes.filter(note => noteId !== note.id)

    dispatch(
      saveNotes(newNotes)
    );
  }
  catch (err) {
    console.log("err", err.response)
  }
}

export const createNote = (note) => async (dispatch, getState) => {
  try {
    const { token } = getState().auth;
    const { notes } = getState().dashboard;

    dispatch(isFetchingSidebarTab());
    const response = await connection({
      method: "post",
      url: `/notas`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: note,
    })
    let newNotes = notes;
    newNotes.push(response.data);

    dispatch(
      saveNotes(newNotes)
    );
  }
  catch (err) {
    console.log("err", err.reponse)
  }
}

export const getTicketsByPatient = (ticket) => async (dispatch, getState) => {
  try {
    const { token } = getState().auth;

    dispatch(isFetchingSidebarTab());
    const response = await getTickets({cpf: ticket.ticket.cpf}, token);
    dispatch(
      saveSelectedTicket({
        history: response.data.hits.hits.map(item => item._source),
        ...ticket
      })
    );
  }
  catch (err) {
    console.log("err", err)
  }
}

export const getTicketsThunk = () => async (dispatch, getState) => {
  try {
    const { token, userdata } = getState().auth;
    const { selectedFilters, firstFetching } = getState().dashboard;
    const oldTickets = getState().dashboard.tickets;

    dispatch(isFetching());
    let tickets = [];
    let filters = {};
    selectedFilters.forEach((filter) => {
      filters[filter.type] = filter.selected;
    });
    if (userdata.profile === 'physician') {
      filters['doctorUsername'] = userdata.name;
    } else {
      filters['doctorType'] = 'enfermeiro';
    }
    const response = await getTickets(filters, token);

    response.data.hits.hits.forEach(ticket => {
      ticket = ticket['_source'];
      const rtcEnabled = ticket.newStatus === 'Aguardando teleconsulta';
      const cannotBeAnswered = ticket.newStatus === 'Finalizado';

      let currentTicket = {
        id: ticket.id,
        date: format(new Date(ticket.createdAt), "dd/MM/yyyy"),
        createdAt: ticket.createdAt,
        pacient: ticket.name,
        location: ticket.address,
        priority: ticket.priority,
        status: ticket.status,
        newStatus: ticket.newStatus,
        client: ticket.client,
        ticket: ticket,
        custom_fields: {},
        questionnaire: ticket.questionnaire && JSON.parse(ticket.questionnaire),
        cannotBeAnswered,
        rtcEnabled,
      };

      if (ticket.appointmentDate) {
        currentTicket.appointmentDateAndHour = format(new Date(ticket.appointmentDate), "dd/MM/yyyy, HH:mm");;
        currentTicket.appointmentDate = format(new Date(ticket.appointmentDate), "dd/MM/yyyy");
      }

      tickets.push(currentTicket);
    });

    if (filters.newStatus.includes('Finalizado')) {
      tickets = tickets.sort(compareValues("createdAt", "desc"));
    } else {
      if (filters.newStatus.includes('Retorno')) {
        tickets = tickets.sort(compareValues("createdAt", "desc"));

        tickets = Array.from(new Set(tickets.map(ticket => ticket.ticket.cpf)))
          .map((cpf) => {
            return tickets.find(ticket => ticket.ticket.cpf === cpf)
          });
      } else {
        tickets = tickets.sort(compareValues("createdAt", "desc"));

        tickets = Array.from(new Set(tickets.map(ticket => {
          return ticket.ticket.cpf
        })))
          .map((cpf) => {
            return tickets.find(ticket => ticket.ticket.cpf === cpf)
          });

        tickets = tickets.reverse();
      }
    }

    let newPatientsIds = [];

    if (firstFetching === false) {
      newPatientsIds = findNewPatients(tickets, oldTickets)

      if (newPatientsIds.length) {
        dispatch(toggleNotification(newPatientsIds));
      }
    }

    dispatch(
      saveTickets({
        tickets
      })
    );
  } catch (err) {
    console.log(err);
  }
};

const findNewPatients = (tickets, oldTickets) => {
  let newPatients = [];

  tickets.forEach(patient => {
    const patientIsOld = oldTickets.find(oldCard => {
      return oldCard.ticket.cpf === patient.ticket.cpf
    });

    if (patientIsOld === undefined) {
      newPatients.push(patient.id)
    }
  })
  return newPatients;
}

export const compareValues = (key, order = "asc") => {
  return (a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
};

export const parseDescription = (description, title, hash, hashKey) => {
  description = description.split(";");
  description.forEach(item => {
    if (item.indexOf(":") !== -1) {
      item = item.split(":");
      if (item[0].trim() === title) {
        hash[hashKey] = item[1].trim();
      }
    } else {
      item = item.split("?");
      if (item[0].trim() === title) {
        hash[hashKey] = item[1].trim();
      }
    }
  });

  return hash;
};

export const updateTicketThunk = (selectedTicket, info) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    dispatch(updateTicketInfo(selectedTicket.id, info));

    connection({
      method: "put",
      url: `/atendimentos/${selectedTicket.id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: info
    }).then((result) => {
      const pacient = {
        ...selectedTicket,
        ...result.data,
        ticket: {
          ...selectedTicket.ticket,
          ...result.data,
        },
        step: 'recommendations-first',
      }
      persistTicketLocalStorage(pacient)
      
      dispatch(saveSelectedTicket(pacient))
      dispatch(getTicketsThunk());
    });
  };
};

export const updateRoomStatus = status => {
  return (dispatch, getState) => {
    const { selectedTicket, selectedFilters } = getState().dashboard;
    const { token } = getState().auth;

    connection({
      method: "put",
      url: `/atendimentos/${selectedTicket.id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        status: status
      }
    }).then(result => {
      const filters = selectedFilters.map(item => item.selected).join(",");
      dispatch(getTicketsThunk(`iniciado_app_covid,${filters}`));

      const pacient = {
        ...selectedTicket,
        ...result.data,
        ticket: {
          ...selectedTicket.ticket,
          ...result.data,
        }
      }

      dispatch(saveSelectedTicket(pacient))

      persistTicketLocalStorage(pacient)
    }).catch((err) => {
    });
  };
};

export const closeTicketThunk = ticketId => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    const { selectedTicket } = getState().dashboard;

    connection({
      method: "put",
      url: `/atendimentos/${ticketId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        status: "Finalizado"
      }
    }).then(result => {
      const pacient = {
        ...selectedTicket,
        ...result.data,
        ticket: {
          ...selectedTicket.ticket,
          ...result.data,
        },
        step: 'recommendations-first',
      }
      // dispatch(saveSelectedTicket(pacient))
      persistTicketLocalStorage(pacient)

      axios.post(
        process.env.REACT_APP_FINISH_NOTIFICATION_URL,
        {
          topic: `${ticketId}`
        }
      );
    }).catch(err => {
      console.log('err', err)
    });
  };
};

const persistTicketLocalStorage = (pacient) => {
  let waitingPatient = localStorage.getItem('waitingPatient');
  waitingPatient = JSON.parse(waitingPatient)

  if (waitingPatient) {
    const newTicket = {
      ...waitingPatient,
      pacient,
    }
    localStorage.setItem('waitingPatient', JSON.stringify(newTicket));
  }
}

export const applyFilterTicketsThunk = info => async (dispatch, getState) => {
  try {
    dispatch(toggleSelectedFilters(info));
    dispatch(getTicketsThunk());
  } catch (err) {}
};

export const sendNotificationThunk = async roomId => {
  axios.post(
    process.env.REACT_APP_CREATE_NOTIFICATION_URL,
    {
      topic: `${roomId}`
    }
  );
};

export const endCallThunk = roomId => {
  axios.post(
    process.env.REACT_APP_FINISH_NOTIFICATION_URL,
    {
      topic: `${roomId}`
    }
  );
};

export const endCallSendEmailThunk = (data) => {
  axios.post(
    process.env.REACT_APP_FINISH_SEND_EMAIL,
    data,
  )
    .then((result) => {
      console.log('result', result)
    })
    .catch((err) => {
      console.log('err', err)
    })
}

export const satisfactionSurveyThunk = (cpf) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    const dnaKey = Math.random().toString(36).slice(2)

    axios({
      method: 'post',
      url: 'https://mj3qmvkyb0.execute-api.us-east-1.amazonaws.com/HML/satisfactionresearch',
      headers: {
        Authorization: `${token}`,
        channel: 'adma',
        dna: dnaKey,
      },
      data: {
        cpf: cpf
      }
    })
      .then((result) => {
        // console.log('result', result)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }
}

export const checkCardState = (selectedTicketId) => async (dispatch, getState) => {
  try {
    const { token } = getState().auth;
    const response = await connection({
      method: "get",
      url: `/atendimentos/${selectedTicketId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
    return response;
  }
  catch (err) {
    console.log("err", err)
  }
}