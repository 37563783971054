// Libs
import React, { Component } from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'

// Styles
const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: ${(props) => (props.me ? 'flex-end' : 'flex-start')}; */
  width: 100%;
  margin: 0.75rem auto;
  padding: 0 1.5rem 0 2.5rem;

  @media (max-width: 677px) {
    width: 90%;
    margin: 0 auto;
  }
`

const Message = styled.div`
  position: relative;
  max-width: 55%;
  margin: 0.375rem 0;
  padding: 0.5rem 4rem 0.5rem 1rem;
  border-radius: 0.5rem;
  background: ${(props) => (props.me ? '#fff' : '#A3E6FF')};
  align-self: ${({ me }) => (me ? 'flex-end' : 'flex-start')};
`

const MessageDate = styled.span`
  position: absolute;
  right: 0.5rem;
  color: #4d4c4f;
  font: 300 0.75rem 'Open Sans', sans-serif;
`

const MessageText = styled.span`
  display: block;
  color: #4d4c4f;
  font: 400 0.8rem 'Open Sans', sans-serif;
  word-break: break-word;
`

class Messages extends Component {
  componentDidUpdate(oldProps) {
    if (oldProps.chats.length !== this.props.chats.length - 1) {
      this.props.scrollbar.scrollTop = this.props.scrollbar.scrollHeight
    }
  }

  render() {
    const { displayName, chats } = this.props

    const me = chats[0].displayName === displayName

    return (
      <MessageContainer>
        {chats.map((message) => {
          return (
            <Message key={message.id} me={me}>
              <MessageDate>{format(message.time, 'HH:mm')}</MessageDate>
              <MessageText>{message.body}</MessageText>
            </Message>
          )
        })}
      </MessageContainer>
    )
  }
}

export default Messages
