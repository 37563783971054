// Lib
import React from 'react'
import styled, { keyframes } from 'styled-components'

// Animations
const animation = keyframes`
	0%{ background-position: -29.25rem 0; }
	100%{ background-position: 29.25rem 0; }
`

// Styles
const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 0.8125rem;
  padding-top: 1.5rem;
  border-radius: 0.3125rem;
  border: 0.0625rem solid #0061af33;
  background: none;
`

const PlaceholderTitle = styled.div`
  position: absolute;
  top: -0.40625rem;
  width: 2.75rem;
  height: 0.8125rem;
  background: #e2ebf2;
  border-radius: 0.25rem;
  /* animation */
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${animation};
  animation-timing-function: linear;
  background: linear-gradient(to right, #e2ebf2 8%, #dddddd 18%, #e2ebf2 33%);
  background-size: 62.5rem 6.5rem;
`

const PlaceholderContent = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-bottom: ${(props) => props.marginBottom};
  background: #e2ebf2;
  border-radius: 0.25rem;
  /* animation */
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${animation};
  animation-timing-function: linear;
  background: linear-gradient(to right, #e2ebf2 8%, #dddddd 18%, #e2ebf2 33%);
  background-size: 62.5rem 6.5rem;
`

const Placeholder = (props) => {
  if (props.type === 'notes') {
    return (
      <Container id='testin'>
        <PlaceholderContent
          width='55%'
          height='.75rem'
          marginBottom='.8125rem'
        />
        <PlaceholderContent
          width='90%'
          height='.75rem'
          marginBottom='.8125rem'
        />
        <PlaceholderContent width='45%' height='.75rem' marginBottom='1rem' />
      </Container>
    )
  }
  return (
    <Container id='testin'>
      <PlaceholderTitle />
      <PlaceholderContent
        width='35%'
        height='1.25rem'
        marginBottom='1.0625rem'
      />
      <PlaceholderContent
        width='80%'
        height='1.25rem'
        marginBottom='1.375rem'
      />
      <PlaceholderContent width='55%' height='.75rem' marginBottom='.8125rem' />
      <PlaceholderContent width='90%' height='.75rem' marginBottom='.8125rem' />
      <PlaceholderContent width='45%' height='.75rem' marginBottom='1rem' />
    </Container>
  )
}

export default Placeholder
