// Action Types
export const LOGIN = "sirio/authentication/LOGIN";
export const LOGOUT = "sirio/authentication/LOGOUT";
export const LOGIN_ERROR = "sirio/authentication/LOGIN_ERROR";
export const CLEAR_ERROR = "sirio/authentication/CLEAR_ERROR";
export const IS_FETCHING = "sirio/authentication/IS_FETCHING";
export const SAVE_TOKEN_CALL = 'sirio/authentication/SAVE_TOKEN_CALL';

export const initialState = {
    token: undefined,
    userdata: undefined,
    error: undefined,
    isFetching: false,
    tokenCall: null,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING:
      return Object.assign({}, state, {
        isFetching: true
      });
    case LOGIN:
      return Object.assign({}, state, {
        token: action.token,
        userdata: action.userdata,
        isFetching: false
      });
    case LOGOUT: {
      return Object.assign({}, state, {
        token: undefined,
        userdata: undefined,
        error: undefined,
        isFetching: false,
        tokenCall: null,
      });
    }
    case LOGIN_ERROR: {
      return Object.assign({}, state, {
        error: action.error,
        isFetching: false
      });
    }
    case CLEAR_ERROR: {
      return Object.assign({}, state, {
        error: undefined
      });
    }
    case SAVE_TOKEN_CALL:
      return Object.assign({}, state, {
        tokenCall: action.info.token
      });
    default:
      return state;
  }
};

// Action Creators
export const isFetching = () => {
  return {
    type: IS_FETCHING
  };
};

export const login = (token, userdata) => ({
  type: LOGIN,
  token,
  userdata
});

export const logout = () => ({
  type: LOGOUT,
});

export const raiseLoginError = error => ({
  type: LOGIN_ERROR,
  error
});

export const clearErrorMessage = () => ({
  type: CLEAR_ERROR
});

export const saveTokenCall = info => ({
  type: SAVE_TOKEN_CALL,
  info,
});