import React from 'react';
import styled, { keyframes } from 'styled-components';


const Content = styled.div`
  position: relative;
  width: calc(100% - 3rem);
  height: calc(100% - 2.5rem);
  margin: 0 auto;
  border-radius: .25rem;
  background: #fff;
  box-shadow: 0 0 .625rem #00000019;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 4.5rem;
  padding: 0 1.5rem 0 2rem;
  background: #fff;
`;

const ChatHistory = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 9.75rem);
	padding-top: 1rem;
  background: #F6F9FC;

  @media (max-width: 667px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Footer = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
  height: 5.25rem;
  padding: 0 2rem;

  @media (max-width: 667px) {
    height: 4.375rem;
    bottom: -4.375rem;
  }
`;

const animation = keyframes`
	 0%{
			background-position: -29.25rem 0;
    }
    100%{
			background-position: 29.25rem 0;
    }
`;

const PlaceholderText = styled.div`
	width: ${props => props.width};
	height: ${props => props.height};
	margin-bottom: ${props => props.marginBottom};
	background: #E2EBF2;
	border-radius: .25rem;
	/* animation */
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: ${animation};
	animation-timing-function: linear;
	background: linear-gradient(to right, #E2EBF2 8%, #dddddd 18%, #E2EBF2 33%);
	background-size: 62.5rem 6.5rem;
	overflow: hidden;
`;

const PlaceholderBorder = styled.span`
	width: ${props => props.width};
	margin: 0 2rem 1rem;
	align-self: ${props => props.beSide};
	padding: 0.625rem 0.375rem;
	border: 1px solid #0061AF20;
	border-radius: 8px;
`;

const PlaceholderChat = () => {
	return (
		<Content>
			<Header>
				<PlaceholderText
					width={'35%'}
					height={'1.25rem'}
					marginBottom={'0'}
				/>
			</Header>
			<ChatHistory>
				<PlaceholderText
					width={'35%'}
					height={'.75rem'}
					marginBottom={'1rem'}
					style={{
						alignSelf: 'center',
					}}
				/>
				<PlaceholderBorder
					width={'25%'}
					beSide={'flex-start'}
				>
					<PlaceholderText
						width={'55%'}
						height={'.875rem'}
						marginBottom={'0'}
					/>
				</PlaceholderBorder>
				<PlaceholderBorder
					width={'40%'}
					beSide={'flex-start'}
				>
					<PlaceholderText
						width={'55%'}
						height={'.875rem'}
						marginBottom={'0'}
					/>
				</PlaceholderBorder>
				<PlaceholderBorder
					width={'50%'}
					beSide={'flex-start'}
				>
					<PlaceholderText
						width={'90%'}
						height={'.875rem'}
						marginBottom={'0'}
						style={{
							marginBottom: '0.5rem',
						}}
					/>
					<PlaceholderText
						width={'55%'}
						height={'.875rem'}
						marginBottom={'0'}
					/>
				</PlaceholderBorder>
				<PlaceholderBorder
					width={'35%'}
					beSide={'flex-end'}
				>
					<PlaceholderText
						width={'55%'}
						height={'.875rem'}
						marginBottom={'0'}
					/>
				</PlaceholderBorder>
				<PlaceholderBorder
					width={'50%'}
					beSide={'flex-end'}
				>
					<PlaceholderText
						width={'90%'}
						height={'.875rem'}
						marginBottom={'0'}
						style={{
							marginBottom: '0.5rem',
						}}
					/>
					<PlaceholderText
						width={'55%'}
						height={'.875rem'}
						marginBottom={'0'}
					/>
				</PlaceholderBorder>
			</ChatHistory>
			<Footer>
				<PlaceholderText
					width={'35%'}
					height={'1.25rem'}
					marginBottom={'0'}
				/>
			</Footer>
		</Content>
	)
}

export default PlaceholderChat;