// Libs
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

// Components
import PacientStatus from './pacient-status'
import FinishConfirmation from './finish-confirmation'

// Redux
import {
  updateTicketThunk,
  closeTicketThunk,
  endCallThunk,
} from '../dataflow/thunks/dashboard-thunks'

const mapStateToProps = (state) => ({
  selectedTicket: state.dashboard.selectedTicket,
  userType: state.auth.userdata.profile,
})

const mapDispatchToProps = (dispatch) => ({
  updateTicketThunk: (ticket, status) =>
    dispatch(updateTicketThunk(ticket, status)),
  closeTicketThunk: (selectedTicket) => {
    dispatch(closeTicketThunk(selectedTicket))
  },
  endCallThunk: (roomId) => endCallThunk(roomId)
})

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  z-index: 3;
`

const Button = styled.button`
  position: absolute;
  bottom: 0.5rem;
  color: #0061af;
  font: 400 0.625rem 'Open Sans', sans-serif;
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: underline;
  outline: none;
`

class ChangeStatus extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: {
        type: '',
        isActive: false,
      },
      selectedStatus: {},
      statusList: [
        {
          type: 'Aguardando teleconsulta',
          label: 'Aguardando teleconsulta',
        },
        {
          type: 'Encaminhamento PA',
          label: 'Encaminhamento PA',
        },
        {
          type: 'Retorno em 1 dia',
          label: 'Retorno em 1 dia',
        },
        {
          type: 'Retorno em 3 dias',
          label: 'Retorno em 3 dias',
        },
        {
          type: 'Finalizado',
          label: 'Finalizado',
        },
      ],
      selectedReturn: 1,
      isOpen: false,
    }
  }

  handleSelectedStatus = (status) => {
    this.setState({
      selectedStatus: status,
      isOpen: false,
    })
  }

  closeModal = () => {
    this.setState({
      selectedStatus: {},
      modal: {
        type: '',
        isActive: false,
      },
    })
  }

  openModal = (type) => {
    this.setState({
      modal: {
        type,
        isActive: true,
      },
    })
  }

  updateStatus = () => {
    const { roomId } = this.props

    const status = {
      status: this.state.selectedStatus.type,
    }

    if (
      this.state.selectedStatus.type === 'Encaminhado para Enfermeiro' ||
      this.state.selectedStatus.type === 'Retorno com Enfermeiro'
    ) {
      let date = new Date()
      date.setDate(date.getDate() + this.state.selectedReturn)

      date = date.toISOString()
      date = `${date.substring(0, date.lastIndexOf('.'))}-03:00`

      status.appointmentDate = date
    }

    if (this.state.selectedStatus.type) {
      if (this.state.selectedStatus.type === 'finalizado') {
        this.props.closeTicketThunk(roomId)
        this.props.leaveAfterAppointment()
      } else {
        this.props.updateTicketThunk(this.props.selectedTicket, status)
      }

      this.redirectToLastScreen()
    }
  }

  saveReturnDate = (date) => {
    this.setState({
      selectedReturn: date,
    })
  }

  handleOpenSelect = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }))
  }

  redirectToLastScreen = () => {
    const { roomId } = this.props
    this.props.endCallThunk(roomId)
    this.props.history.replace({
      pathname: '/atendimento',
    })
    this.props.handleCloseChat()
  }

  renderEndCall = () => {
    const { modal } = this.state

    if (modal.isActive && modal.type === 'pacientStatus') {
      return (
        <PacientStatus
          handleSelectedStatus={this.handleSelectedStatus}
          selectedStatus={this.state.selectedStatus}
          statusList={this.state.statusList}
          closeModal={this.closeModal}
          pacient={this.props.pacient}
          updateStatus={this.updateStatus}
          saveReturnDate={this.saveReturnDate}
          isOpen={this.state.isOpen}
          selectedReturn={this.state.selectedReturn}
          userType={this.props.userType}
          handleOpenSelect={this.handleOpenSelect}
          noEmail
        />
      )
    }

    if (modal.isActive && modal.type === 'finishConfirmation') {
      return (
        <FinishConfirmation
          closeModal={this.redirectToLastScreen}
          pacient={this.props.pacient}
          selectedStatus={this.state.selectedStatus}
        />
      )
    }
  }

  render() {
    return (
      <Container>
        <Button
          onClick={() => {
            this.openModal('pacientStatus')
          }}
        >
          editar
        </Button>
        {this.renderEndCall()}
      </Container>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatus)
