/* eslint-disable no-plusplus */
import React from 'react'
import styled, { keyframes } from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  max-width: calc(100% - 28rem);
  height: 100%;
  padding: 0 2rem;
  padding-top: 1.5rem;
  background: none;
  overflow: hidden;
`

const PlaceholderCard = styled.div`
  position: relative;
  width: 30%;
  min-width: 14.375rem;
  max-width: 18.75rem;
  height: max-content;
  min-height: 9.125rem;
  margin: 0 0.875rem 2.25rem;
  padding: 1.125rem 0.875rem 2.375rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 0.4375rem;
  box-shadow: 0 0 0.125rem 0.0625rem #00000010;
`

const animation = keyframes`
	 0%{
        background-position: -29.25rem 0;
    }
    100%{
        background-position: 29.25rem 0;
    }
`

const PlaceholderContent = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-bottom: ${(props) => props.marginBottom};
  background: #e2ebf2;
  border-radius: 0.25rem;
  /* animation */
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${animation};
  animation-timing-function: linear;
  background: linear-gradient(to right, #e2ebf2 8%, #dddddd 18%, #e2ebf2 33%);
  background-size: 62.5rem 6.5rem;
  overflow: hidden;
`

const PlaceholderButtom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2.375rem;
  margin-bottom: ${(props) => props.marginBottom};
  background: #e2ebf2;
  border-radius: 0 0 0.25rem 0.25rem;
  /* animation */
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${animation};
  animation-timing-function: linear;
  background: linear-gradient(to right, #e2ebf2 8%, #dddddd 18%, #e2ebf2 33%);
  background-size: 62.5rem 6.5rem;
  overflow: hidden;
`

const Placeholder = () => (
  <PlaceholderCard>
    <PlaceholderContent width='70%' height='1.25rem' marginBottom='0.25rem' />
    <PlaceholderContent width='70%' height='0.75rem' marginBottom='1rem' />
    <PlaceholderContent width='30%' height='0.75rem' marginBottom='1rem' />
    <PlaceholderButtom width='30%' height='0.75rem' />
  </PlaceholderCard>
)

const mountPlaceholder = () => {
  // const container = document.querySelector('#testin') && { width: document.querySelector('#testin').offsetWidth , height: document.querySelector('#testin').offsetHeight};
  // const card = document.querySelector('#testin2') && { width: document.querySelector('#testin2').offsetWidth, height: document.querySelector('#testin2').offsetHeight};
  // if (container && card) {
  // 	// const collumns = container.width / card.width;
  // 	// const rows = container.height / card.height;
  // 	// const cards = parseInt(collumns) * parseInt(rows);

  // } else {
  // 	return null;
  // }
  const placeholders = []
  for (let i = 0; i < 100; i++) {
    placeholders.push(<Placeholder key={i} />)
  }
  return placeholders.map((item) => item)
}

const Card = () => {
  return <Container id='testin'>{mountPlaceholder()}</Container>
}

export default Card
