// Libs
import React from 'react'
import styled from 'styled-components'

// Images
import sended from '../assets/sended.svg'

// Styled
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.25rem);
  z-index: 10;
`

const InnerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25rem;
  padding: 4rem 2.25rem 2rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0 0 0.625rem #00000029;
  border-radius: 0.375rem;
`

const Title = styled.p`
  margin: 0.5rem 0;
  margin-bottom: 1.5rem;
  color: #0561af;
  font: 700 1rem 'Open Sans', sans-serif;
`

const CloseButton = styled.button`
  width: 6.25rem;
  margin: 1.875rem 0.652rem 0;
  padding: 0 0.5rem;
  border: none;
  background: none;
  text-decoration: underline;
  font: 400 0.875rem Open Sans;
  letter-spacing: 0;
  color: #0061af;
  cursor: pointer;
  outline: none;
`

const Img = styled.img`
  position: absolute;
  top: 2rem;
  left: 2rem;
  width: 6.25rem;
`

const EmailSended = (props) => (
  <Container onClick={props.closeModal}>
    <InnerContainer onClick={(ev) => ev.stopPropagation()}>
      <Img src={sended} />
      <Title>E-mail enviado com sucesso :)</Title>
      <CloseButton onClick={props.closeModal}>Voltar</CloseButton>
    </InnerContainer>
  </Container>
)

export default EmailSended
